import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    Flex
} from "@chakra-ui/react";
import { GiPadlock } from "react-icons/gi";
import { BsDiscord, BsWhatsapp} from 'react-icons/bs'
import {IoMdClose} from 'react-icons/io'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ModalPermission = ({openModal, dataKey, closeModal}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const data = {
        linkToDiscord: {
            width: '800px',
            link: 'https://investidormoderno.net/livetrading',
            linkText: 'Conheça mais sobre a BELO TRADE',
            title: `VINCULE SUA CONTA AO DISCORD`,
            description: 'Para ter acesso a esta funcionalidade e muito mais, entre no Servidor da BeloTrade no Discord e digite em qualquer canal o comando: /vincular',
        },
        check: {
            width: '800px',
            link: 'https://investidormoderno.net/livetrading',
            linkText: 'Conheça o LIVE TRADING',
            title: `ASSINE O LIVE TRADING`,
            description: 'Para ter acesso a esta funcionalidade e muito mais, volte ao servidor da BeloTrade no Discord e assine o LIVE TRADING digitando, em qualquer canal, o comando: /assinar',
        },
        enterServer: {  
            width: '450px',
            link2: 'https://api.whatsapp.com/send?phone=5531984058891&text=Olá+precio+de+ajuda+sobre+o+acesso+à+Research',
            linkText2: 'Preciso de ajuda',
            link: 'https://investidormoderno.net/livetrading',
            linkText: 'Sobre a BeloTrade',
            title: `ENTRE NO SERVIDOR DA BELOTRADE`,
            description: 'Para ter acesso a esta funcionalidade e muito mais, entre novamente no servidor da BeloTrade no Discord',
        },

    }

    useEffect(() =>{ 
        console.log('era p abrir?')
        console.log(dataKey)
        if(!openModal) return
 
        onOpen(true)
    },[onOpen, openModal])

    function handleClickCloseModal(){
        closeModal()
        onClose(true)
    }

    return (
        <>
        <Modal 
            isOpen={isOpen} 
            onClose={handleClickCloseModal} 
            size={"3xl"}>
            <ModalOverlay />
            <ModalContent 
                width={data[dataKey].width}
                style={{
                    alignItems:'center',
                    padding:'30px',
                    color:'white',
                    background: 'radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(255, 255, 255, 0.28) 0%, rgba(255, 255, 255, 0) 100%)',
                    backdropFilter:'blur(21px)',
                    borderRadius: '20px',
                    border:'3px solid white'}}>
                <ModalHeader align={'center'}>{data[dataKey].title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    {/* {description} */}
                    <Text fontSize={'20px'} align={'center'}>
                        {data[dataKey].description}
                    </Text>
                </ModalBody>
                <ModalFooter 
                    style={{display: 'flex', flexDirection:'column', width:'100%'}}>
                    <Flex 
                        width={'100%'}
                        justifyContent={'space-around'} 
                        flexWrap={dataKey !== 'linkToDiscord' ? 'wrap': 'nowrap'}>
                        <a 
                            href={'https://discord.gg/JFKk4eZD2f'}>
                            <Button 
                                display={'flex'}
                                marginTop={'8px'}
                                border={'1px solid white'}
                                borderRadius={'5px'}
                                minWidth={'200px'}
                                padding={'8 px'}
                                backgroundColor={'rgba(0, 0, 0, 0)'}
                                _hover={{boxShadow: '1px 1px 1px 1px gray'}}>
                            <BsDiscord color={'#7289da'} size={'25px'} style={{marginRight: '6px'}}/>
                            <Text fontSize={'10pt'}>
                                ENTRAR NA BELO TRADE
                            </Text>
                        </Button>
                        </a>
                        {(dataKey !== 'enterServer') && 
                            <a 
                                href={'https://api.whatsapp.com/send?phone=5531984058891&text=Olá+precio+de+ajuda+sobre+o+acesso+à+Research'}>
                            <Button 
                                boxSizing={"border-box"}
                                marginTop={'8px'}
                                border={'1px solid white'}
                                backgroundColor={'rgba(0, 0, 0, 0)'}
                                colorScheme='blue'
                                minWidth={'200px'}
                                _hover={{boxShadow: '1px 1px 1px 1px gray'}}>
                            <BsWhatsapp color="#25D366" size={'30px'} style={{marginRight: '6px'}}/>
                            <Text fontSize={'10pt'}>
                                PRECISO DE AJUDA
                            </Text>
                        </Button></a>}
                        <Button
                            boxSizing={"border-box"}
                            marginTop={'8px'}
                            border={'1px solid white'} 
                            colorScheme='blue' 
                            onClick={handleClickCloseModal} 
                            minWidth={'210px'}
                            backgroundColor={'rgba(0, 0, 0, 0)'}
                            _hover={{boxShadow: '1px 1px 1px 1px gray'}}>
                            <IoMdClose color="#D1D4D6" size={'35px'}/>
                            <Text fontSize={'10pt'}>
                                CANCELAR
                            </Text>
                        </Button>
                    </Flex>
                    <div 
                        style={{
                            display:'flex',
                            justifyContent:`${data[dataKey].link2 ? 'space-around': 'start'}`, 
                            width:'100%', 
                            marginTop:'20px'}}>
                        <a href={data[dataKey].link} >
                            <Text fontSize={'16px'} textDecoration={'underline'}>{data[dataKey].linkText}</Text>
                        </a>
                        <a href={data[dataKey].link2}>
                            <Text fontSize={'16px'}>{data[dataKey].linkText2}</Text>
                        </a>
                    </div>
                </ModalFooter>
            </ModalContent>
        </Modal >
    </>
    )
}
 
export default ModalPermission;