import { Avatar, Box, Button, Circle, Divider, Flex, Image, Input, Text } from "@chakra-ui/react";
import styles from './LinkDiscord.module.css'
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import { BsDiscord, BsWhatsapp} from 'react-icons/bs'
import { ImCheckboxChecked} from 'react-icons/im'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TableData from "../../services/TableDataService";

const LinkDiscord = () => {

    const navigate = useNavigate()

    useEffect(()=>{
        async function fetchData() {
            let response = await TableData.getTableData('Lsr')
            console.log('LinkDiscord')
            console.log(response)
            if (response.statusCode > 400) {
                toast.error(response.data.message || response.message)
                navigate('/')
            }
        }
        fetchData()
    },[])
    const handleClickInWhatsapp = () => {
    }

    return (
        <div className={styles.container}>
            <div >
                <Flex flexDirection={'column'} alignItems={'center'} color={'white'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Vincule sua conta ao <span style={{color: '#7289da'}}>discord</span>
                    </Text>
                    <NotificationSongGlobal />
                    <Flex 
                        color={'white'}
                        w={'60%'} 
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        >
                                <Circle bgColor={'#50d89f'} w={'60px'} h={'60px'} >
                                    <Text fontSize={'30px'} fontWeight={'bolder'}>
                                        1
                                    </Text>
                                </Circle>
                                <Divider color={'white'} borderStyle="dashed"/>
                                <Circle bgColor={'#50d89f'} w={'60px'} h={'60px'}>
                                    <Text fontSize={'30px'} fontWeight={'bolder'} color={'#424549'}>
                                        2
                                    </Text>
                                </Circle>
                                <Divider color={'white'} borderStyle="dashed"/>
                                <Circle bgColor={'#50d89f'} w={'60px'} h={'60px'}>
                                    <Text fontSize={'30px'} fontWeight={'bolder'}>
                                        3
                                    </Text>
                                </Circle>
                    </Flex>
                    <Flex w={'80%'} justifyContent={'space-around'} marginTop={'15px'}>
                        <Box 
                            alignItems={'center'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-around'}
                            border={'1px solid white'} 
                            borderRadius={'10px'}
                            maxWidth={'25%'} 
                            minHeight={'400px'}
                            padding={'15px'}>
                            <Text color={'white'} fontSize={'20px'} textAlign={'center'} fontFamily={'Poppins'}>
                                Crie sua conta gratuita na research e vincule ao nosso servidor no discord com o comando /vincular
                            </Text>
                            <Avatar   
                                w={'70px'} 
                                h={'70px'} 
                                bg='white' 
                                icon={<img src={'favicon.ico'} alt='logo' width={'50px'}/>} />
                                <Box
                                    marginTop={'-25px'}
                                    display="inline-block"
                                    borderLeft="1px solid gray"
                                    height="100px"
                                    marginRight="0px"
                                    marginBottom={'-30px'}
                                    color={'#50d89f'}
                                />
                            <Button
                                borderRadius={'20px'}
                                bgColor={'#424549'}
                                _hover={{
                                    bgColor:'#52565c'
                                }}
                                w='160px'   
                                cursor='pointer'>
                                    <Text mr={'3px'}>Servidor</Text>
                                    <BsDiscord color={'#7289da'} size={'30px'} id={'icon'}/>   
                            </Button>
                            <Box
                                    marginTop={'-15px'}
                                    display="inline-block"
                                    borderLeft="1px solid gray" 
                                    height="100px"
                                    marginRight="0px"
                                    marginBottom={'-30px'}
                                />
                            <Box backgroundColor={'white'} borderRadius={'10px'}>
                                <ImCheckboxChecked size={'50px'} color={'#8ebf30'}/>
                            </Box>

                        </Box>
                        <Box 
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-around'}
                            border={'1px solid white'} 
                            borderRadius={'10px'}
                            maxWidth={'25%'}  
                            minHeight={'400px'}
                            padding={'15px'}>
                            <Text color={'white'} fontSize={'20px'} textAlign={'center'} fontFamily={'Poppins'}>
                                Adiquira alguma das seguintes permissões para ter acesso ilimitado na plataforma 
                            </Text>
                            <Flex justifyContent={'space-between'} mt={'10px'}>
                            <Box
                                borderLeft={'3px solid gray'} 
                                display={'flex'} 
                                flexDirection={'column'}
                                padding={'10px'}
                                width={'100%'}
                                height={'200px'}
                                overflow={'auto'}
                                >
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Associado
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Estagiário
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Trial
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Research
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Trader
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Trader Senior
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Suporte
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <ImCheckboxChecked color={'#8ebf30'}/> &nbsp; @Orientador
                                </Box>
                            </Box>

                        </Flex>
                        </Box>
                        <Box 
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'space-around'}
                            borderRadius={'10px'}
                            border={'1px solid white'} 
                            maxWidth={'25%'} 
                            minHeight={'400px'}
                            padding={'15px'}>
                            <Text color={'white'} fontSize={'20px'} textAlign={'center'} fontFamily={'Poppins'}>
                                Conheça a BeloTrade e tire suas duvidas direto com nosso suporte
                            </Text>
                            <a href="https://investidormoderno.net/livetrading"> 
                                <Button
                                    bgColor={'#424549'}
                                    _hover={{
                                        bgColor:'#52565c'
                                    }}
                                    borderLeft={'3px solid gray'}
                                    w='200px'
                                    h='50px'
                                    cursor='pointer'>
                                        <Text mr={'3px'}>Belo Trade</Text>
                                        <img src={'favicon.ico'} alt="Research logo" width="28px" />
                                </Button>
                            </a> 

                            <a 
                                href="https://api.whatsapp.com/send?phone=5531984058891&text=Olá+precio+de+ajuda+sobre+o+acesso+à+Research">
                                <Button
                                    borderLeft={'3px solid gray'}
                                    color={'white'}
                                    bgColor={'#424549'}
                                    _hover={{
                                        bgColor:'#52565c'
                                    }}
                                    w='200px'
                                    h='50px'
                                    onClick={handleClickInWhatsapp}
                                    cursor='pointer'>
                                        <Text mr={'3px'}>Suporte</Text>
                                        <BsWhatsapp size={'32px'} color="#25D366"/>
                                </Button>
                            </a>
                        </Box>
                    </Flex>
                </Flex>
            </div>
        </div>

    );
}
 
export default LinkDiscord;