import { chakra } from "@chakra-ui/react";

// TODO:fazer o media query
const Td = chakra('td', {
    baseStyle: {
        padding: '10px',
        paddingTop: '20px',
        width: '150px',
        border: '1px solid black',
        textAlign: 'start',
        boxSizing: 'border-box'
    }
})

export default Td;