import { BrowserRouter } from 'react-router-dom'
import NavBar from './components/NavBar/NavBar';
import FilterProvider from './hooks/Filter/Filter'
import AuthProvider from './hooks/Auth';
import AppRoutes from './routes';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import PermissionProvider from './hooks/Permission';

function App() {

  return (
    <div className="App">
      <AuthProvider>
        <FilterProvider>
          <PermissionProvider>
            <BrowserRouter>
              <NavBar />
              <AppRoutes />
              <Footer />
              <ToastContainer />
            </BrowserRouter>
          </PermissionProvider>
        </FilterProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
