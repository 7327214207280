import { api, requestConfig } from "../utils/config";


// Delete a strategie
// const deleteStrategie = async (name) => {
//     const config = requestConfig("DELETE", null);

//     try {
//         const res = await fetch(`${api}/strategies/${name}`, config)
//             .then((res) => {
//                 const statusCode = res.status;
//                 return res.json().then((obj) => ({
//                     statusCode: statusCode,
//                     data: obj,
//                 }));
//             })
//             .catch((err) => ({
//                 errors: ["Houve um erro, por favor tente mais tarde."],
//             }));

//         return res;
//     } catch (error) {
//         console.log(error);
//     }
// };
// Delete a strategie
const getAlertsTradingView = async (name) => {
    const config = requestConfig("GET", null);
    try {
        const res = await fetch(`${api}/tradingview${name ? '/' + name : ''}`, config)
            .then((res) => {
                const statusCode = res.status;
                return res.json().then((obj) => ({
                    statusCode: statusCode,
                    data: obj,
                }));
            })
            .catch((err) => ({
                errors: ["Houve um erro, por favor tente mais tarde."],
            }));

        return res;
    } catch (error) {
        console.log(error);
    }
};

const getAlertsTrdr = async (name) => {
    const config = requestConfig("GET", null);
    try {
        const res = await fetch(`${api}/trdr${name ? '/' + name : ''}`, config)
            .then((res) => {
                const statusCode = res.status;
                return res.json().then((obj) => ({
                    statusCode: statusCode,
                    data: obj,
                }));
            })
            .catch((err) => ({
                errors: ["Houve um erro, por favor tente mais tarde."],
            }));

        return res;
    } catch (error) {
        console.log(error);
    }
};

const alerts = {
    //   deleteStrategie,
    getAlertsTradingView,
    getAlertsTrdr
};

export default alerts;
