import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { FilterContext } from "../../hooks/Filter/Filter";

// saveFilter, column, clearFilter, clickedState, handleClickInFilter, clear, defaultFilter = true 
const Filter = ({ column, defaultFilter = true, handleClickInFilter, saveFilter, clickedState }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()


    const { filters, updateFilter, clearFilter } = useContext(FilterContext)
    const [configFilter, setConfigFilter] = useState(
        (filters && filters[column]) ? { filter: filters[column]['filter'], value: filters[column]['value'] } : {})

    const save = (column, value) => {
        setConfigFilter({ ...configFilter, [column]: value })
    }
    function saveConfig() {
        updateFilter(column, configFilter)
        onClose()
    }
    function removeFilter() {
        clearFilter(column)
        onClose()
    }

    // function clearConfig() {
    //     setConfigFilter()
    //     clearFilter(configFilter, column)
    //     handleClickInFilter(!clickedState)
    //     onClose()
    // }

    // useEffect(() => {
    //     setConfigFilter()
    // }, [clear])

    return (
        <>

            {filters && Object.keys(filters).includes(column)
                ? (
                    <FiFilter
                        fontSize='18px'
                        cursor='pointer'
                        onClick={onOpen}
                        transition='0.2s'
                        z-index='200'
                        color="red"
                    />) : (
                    <FiFilter
                        fontSize='18px'
                        cursor='pointer'
                        onClick={onOpen}
                        transition='0.2s'
                        z-index='200'
                    />)
            }
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent width={'300px'} bg={'primary'} border={'1px solid white'}>
                    <ModalHeader color={'white'}>Filtrar por {column}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Flex flexDirection={'column'} alignItems={'center'} >
                            {column === 'Símbolos' || column === 'Símbolo' ? (<></>) : (
                                <Select
                                    width={200} marginY={5} color={'white'} bgColor={'primary'} onChange={(e) => save('filter', e.target.value)}
                                    defaultValue={(filters && filters[column]) ? filters[column]['filter'] : '-'}
                                >
                                    <option value='-' style={{ backgroundColor: "black" }}>Selecione seu filtro</option>
                                    <option value='<' style={{ backgroundColor: "black" }}>Menor que</option>
                                    <option value='<=' style={{ backgroundColor: "black" }}>Menor ou igual a</option>
                                    <option value='>' style={{ backgroundColor: "black" }}>Maior que</option>
                                    <option value='>=' style={{ backgroundColor: "black" }}>Maior ou igual a</option>
                                </Select>
                            )}
                            <Input
                                marginY={5}
                                placeholder={(filters && filters[column]) ? filters[column]['value'] : 0}
                                width={200} color={'text'}
                                onChange={(e) => setConfigFilter({ ...configFilter, 'value': e.target.value })} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex>
                            {/* <Button colorScheme='blue' mr={3} onClick={() => clearConfig()}> */}
                            <Button colorScheme='blue' mr={3} onClick={() => removeFilter()}>
                                Limpar
                            </Button>
                            {/* <Button color={'black'} onClick={() => saveConfig()}> */}
                            <Button color={'black'} onClick={() => saveConfig()}>
                                Aplicar
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    );
}

export default Filter;