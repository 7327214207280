import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import backgroundImageLogin from '../../assets/dashboard-login.png'
import { useEffect, useState } from "react";
import authService from "../../services/authService";
import { toast } from "react-toastify";

import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

const ForgotPassword = () => {
    const { code } = useParams()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    })
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchVerification() {
            try {
                if (!code) {
                    toast.success('Código inválido. ')
                    return
                }
                let response = await authService.checkForgotPasswordCode({ code })
                if (response.statusCode >= 400) {
                    toast.error(response.error)
                }
            } catch (error) {
                setLoading(false)
                toast.success('Algo deu errado. ')
            }
        }
        fetchVerification()
    }, [])

    async function handleClickInSend() {
        setLoading(true)
        if ((!data.password || !data.confirmPassword) || (data.password !== data.confirmPassword)) {
            toast.error('Os campos de password precisam ser iguais. ')
            setLoading(false)
            return
        }
        let response = await authService.resetPassword({ code: code, newPassword: data.password })

        if (response.statusCode >= 400) {
            setLoading(false)
            toast.error(response.error)
        } else {
            setLoading(false)
            toast.success(response.message)
            navigate('/login')
        }

    }

    return (
        <Box maxW={'1920px'} h={'100vh'} bg={'#000000'}>
            <Box h={'90%'} backgroundImage={`url(${backgroundImageLogin})`} backgroundPosition={'100% 35%'} margin={'auto'}>
                <Flex
                    // bg={"red"}
                    flex={'1'}
                    height={'100%'}
                    flexDirection={'column'}>
                    <Box maxW={'95rem'} h={'148px'} alignItems={'center'} margin={'auto'}>
                        <Text
                            color={'white'}
                            fontSize={'42px'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                            marginTop={'35px'}
                        >
                            BELO TRADE RESEARCH
                        </Text>
                        <Box maxW={'95rem'} h={'59px'} alignItems={'center'}>
                            <Text
                                color={'white'}
                                fontSize={'16px'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                marginTop={'15px'}
                            >
                                TODAS  AS  INFORMAÇÕES  PARA  UM  <i>CRYPTO TRADER</i>  &nbsp;EM  UM  SO  LUGAR
                            </Text>
                        </Box>
                    </Box>
                    <Flex
                        w={'50%'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        margin={'auto'}>
                        <Text
                            fontFamily={'Poppins'}
                            marginBottom={'30px'}
                            align={'center'}
                            fontSize={'16px'}
                            color={'white'}>
                            Digite sua nova senha
                        </Text>
                        <Input
                            type={'password'}
                            placeholder={'Senha'}
                            w={'100%'}
                            color={'white'}
                            borderColor={(data.password === data.confirmPassword) ? 'white' : 'red'}
                            onChange={(e) => setData({ ...data, 'password': e.target.value })}
                        />
                        <Input
                            mt={'15px'}
                            type={'password'}
                            placeholder={'Confirme sua senha'}
                            color={'white'}
                            w={'100%'}
                            borderColor={(data.password === data.confirmPassword) ? 'white' : 'red'}
                            onChange={(e) => setData({ ...data, 'confirmPassword': e.target.value })}
                        />
                        <Box
                            w={'100%'}
                            align={'center'}>
                            <Button
                                // mr={'10px'}
                                mt={'25px'}
                                w={'90px'}
                                variant='outline'
                                color={'white'}
                                _hover={{ color: 'activeGreen', boxShadow: '1px 1px gray' }}
                                onClick={handleClickInSend}
                            >
                                {loading ? <Spinner /> : 'ENVIAR'}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
                {/* <Image src={vectorRegister} w={'650px'} h={'600px'} /> */}
            </Box>
        </Box >
    );
}

export default ForgotPassword;