import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAX4bUMeBGBSX6FtfUH-wLIvK-NEIrEwGo",
  authDomain: "research-auth-9469c.firebaseapp.com",
  projectId: "research-auth-9469c",
  storageBucket: "research-auth-9469c.appspot.com",
  messagingSenderId: "423404843933",
  appId: "1:423404843933:web:bc447b4e081b7312673574"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)