import { Flex, Grid, GridItem, Table, Text } from '@chakra-ui/react'
import { TableContainer, Tbody, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './Table.module.css'
import userService from "../../../../services/userService";
import { toast } from "react-toastify";
import Td from '../../../../components/TableComponents/Td';
import MenuActions from '../MenuAction/MenuAction'
import authService from "../../../../services/authService";

const TablePerson = ({ columns, dataTable }) => {
    const [showEmail, setShowEmail] = useState(false);
    // let [appliedFilter, setAppliedFilter] = useState({})
    let [dataFilter, setDataFilter] = useState([])
    // let [clickedInFilter, setClickedInFilter] = useState(true)


    useEffect(() => {
        // if (dataTable.length)
        setDataFilter(dataTable)
    }, [dataTable])

    const handleMouseEnter = (email) => {
        if(email.length > 15){
            setTimeout(()=>{
                setShowEmail(true);
            },300)
        }

    };

    const handleMouseLeave = () => {
        setShowEmail(false);
        
    };

    async function handelClickCheckAccount(email) {

        let response = await authService.resendVerificationCode({ email })
        if (response.statusCode === 201 || response.statusCode === 200) {
            toast.success('Email enviado com sucesso. ')
        } else {
            toast.error(response.message || response.error)
        }
    }

    async function handelClickForgotPassword(email) {
        let response = await authService.sendEmailForgotPasswordCode({ email })

        if (response.statusCode === 201 || response.statusCode === 200) {
            toast.success('Email enviado com sucesso. ')
        }
    }

    async function deleteUser(id) {
        try {
            let response = await userService.deleteUser(id)
            let newFilter = dataFilter.filter(u => (
                u.id !== id
            ))
            toast.success(response?.data.message)
            setDataFilter(newFilter)
        } catch (error) {
            console.log(error)
            toast.error('Algo deu errado ao deletar o usuário ')
        }   
    }

    async function updateUserType(id){
        try {
            let response = await userService.updateUserType(id)
            let newFilter = dataFilter.map(u => {
                if(u.id === id)
                    return {...u, type:u.type === 'common' ? 'senior' : 'common'}
                return {...u}
            })
            toast.success(response?.data.message)
            setDataFilter(newFilter)
        } catch (error) {
            console.log(error)
            toast.error('Algo deu errado ao atualizar o tipo do usuário ')
        }   
    }

    async function removeDiscordId(id){
        try {
            let response = await userService.updateUser(id, {discordId: ''})
            let newFilter = dataFilter.map(u => {
                if(u.id === id)
                    return {...u, discordId:''}
                return {...u}
            })
            toast.success(response?.data.message)
            setDataFilter(newFilter)
        } catch (error) {
            console.log(error)
            toast.error('Algo deu errado ao atualizar o tipo do usuário ')
        }   
    }

    return (<>
        <TableContainer borderRadius={30} overflowX={'hidden'}>
            <Table variant={'unstyled'} textColor={'text'}>
                <Thead bg={'secondary'} display={'block'}>
                    <Tr>
                        {columns.map(th => (
                            // onClick={() => { DataOrderingByColumn(th) }}
                            <Th key={th}
                                textColor={'text'}
                                _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                                cursor='pointer'
                                p={0}
                                minWidth={'150px'}
                                maxWidth={'150px'}
                                h={50}>
                                <Flex justifyContent={'center'}>
                                    <span className={styles.valueTable}>{th}</span>
                                </Flex>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody overflowX={'auto'} overflowY={'auto'} maxHeight={'450px'} display={'block'}>
                    {dataFilter ?
                        dataFilter.map(((row, index) => <Tr key={index}>
                            <Td maxW={'150px'} overflowY={'auto'} css={{
                                '&::-webkit-scrollbar': {
                                    width: '3px',
                                    height: '10px'
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    background: 'rgb(34, 33, 33);',
                                    borderRadius: '24px',
                                  },
                            }}>
                                <Flex>
                                    {/* &nbsp; &nbsp; */}
                                    {row.username}
                                </Flex>
                            </Td>
                            <Td
                               maxW={'150px'}
                               overflowY={'auto'}
                               css={{
                                '&::-webkit-scrollbar': {
                                    width: '3px',
                                    height: '10px'
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    background: 'rgb(34, 33, 33);',
                                    borderRadius: '24px',
                                  },
                            }}
                                >
                                {/* <Text 
                                    whiteSpace={'nowrap'}
                                    w={'130px'}
                                    bgColor={'green'} 
                                     _hover={{w: row.email.length > 15 ? '220px': '150px'}}
                                    overflow={'hidden'}
                                    textOverflow={'ellipsis'}>
                                    {row.email} */}
                                 {row.email}
                                 {/* </Text> */}
                            </Td>
                            <Td w={'150px'} color={row.checked ? '#22c55e': '#f1f1f184'}>
                                {row.checked ? 'Verificado': 'Pendente'}
                            </Td>
                            <Td w={'150px'} color={row.type === 'senior' ? '#7289da': 'white'}>
                                {row.type}
                            </Td>
                            <Td w={'150px'} >
                                {/* {row.discordId 
                                    ? row.discordId.length > 6 ? `${row.discordId.slice(0,6)}...` : row.discordId
                                    : 'Nenhum'} */}
                                    {row.discordId}
                            </Td>
                            <Td w={'150px'} >
                                <MenuActions 
                                    id={row.id} 
                                    email={row.email}
                                    deleteUser={deleteUser} 
                                    checkAccount={handelClickCheckAccount} 
                                    resetPassword={handelClickForgotPassword}
                                    changeUserType={updateUserType}
                                    removeDiscordId={removeDiscordId}
                                    type={row.type}/>
                            </Td>
                        </Tr>)) : (<></>)}
                </Tbody>
                <Tfoot bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            <Th key={th}></Th>
                        ))}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer >
    </>

    );
}

export default TablePerson;