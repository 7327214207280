import { Flex, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import styles from './Home.module.css'
import TablePerson from "../../components/Table/Table";
import { useState } from "react";
import { useEffect } from "react";
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";
import TableData from '../../services/TableDataService'
import UserService from '../../services/userService'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PermissionContext } from "../../hooks/Permission";

const Home = () => {
    const columnsFix = {
        'Símbolos': false,
        'LSR': false,
        '15m Δ%': false,
        '1h Δ%': false,
        '4h Δ%': false,
        '24h Δ%': false
    }
    const navigate = useNavigate()
    const { LoadPermission } = useContext(PermissionContext)
    const [data, setData] = useState(JSON.parse(localStorage.getItem('LsrPage')))
    const [updatedPage, setUpdatedPage] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    const [columns, setColumns] = useState(columnsFix)
    const [order, setOrder] = useState(true)
    const [symbols, setSymbols] = useState([])
    const [filterApplied, setFilterApplied] = useState([])
    const [updateTable, setUpdateTable] = useState(false)
    const [lsrGlobal, setLsrGlobal] = useState(0)

    function updateSymbols(newData, column) {
        let newColumnsConfig = columnsFix
        newColumnsConfig[column] = columns[column] ? false : true
        setOrder(!order)
        setColumns(newColumnsConfig)
        setData(newData)
    }

    function handleFilterTable(newData, column) {
        if (filterApplied.length && filterApplied.indexOf(column) !== -1) {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setData(newData)
        } else {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setFilterApplied([...filterApplied, column])
            setData(newData)
        }
    }

    function OrderByLsr(dataTable) {
        dataTable[0].symbols = dataTable[0].symbols.sort((a, b) => {
            if (parseFloat(dataTable[1].lsr[a]) > parseFloat(dataTable[1].lsr[b]))
                return 1
            if (parseFloat(dataTable[1].lsr[a]) < parseFloat(dataTable[1].lsr[b]))
                return -1
        }).reverse()
        return dataTable
    }

    function formatData(data, symbol) {
        setLsrGlobal(parseFloat(data['Lsr'][0]['LsrOrOIGlobal']))
        setLastUpdatedAt(new Date(data['Lsr'][0].createdAt))
        symbol = symbol.map(e => e.symbol)
        let newData = { 'lsr': {}, '15m': {}, '1h': {}, '4h': {}, '24h': {}, symbols: symbol }
        data['Lsr'].map(d => {
            newData['lsr'] = { ...newData['lsr'], [d.symbol]: parseFloat(d['LsrOrOI']) }
            newData['15m'] = { ...newData['15m'], [d.symbol]: parseFloat(d['15m']) }
            newData['1h'] = { ...newData['1h'], [d.symbol]: parseFloat(d['1h']) }
            newData['4h'] = { ...newData['4h'], [d.symbol]: parseFloat(d['4h']) }
            newData['24h'] = { ...newData['24h'], [d.symbol]: parseFloat(d['24h']) }
        })

        return newData
    }

    useEffect(() => {

        async function fetchData() {
            try {
                console.log('Estou aqui')
                console.log(updatedPage)
                let symbols = await TableData.getSymbols()
                let response = await TableData.getTableData('Lsr')
                let discordId = localStorage.getItem('discId')
                if (response.statusCode >= 400 || symbols.statusCode >= 400) {
                    toast.error(response.data.message || response.data.error)
                    if(discordId){
                        toast.warning('Faça Login para vincular sua conta ao discord. ')
                    }
                    navigate('/login')
                } else {
                    let dataFormated = formatData(response['data'], symbols['data'])
                    localStorage.setItem('LsrPage', JSON.stringify(dataFormated))
                    setData(dataFormated)
                    setUpdateTable(true)
                    if(discordId && !updatedPage){
                        // localStorage.setItem('permissions', JSON.stringify(permissions))
                        let user = JSON.parse(localStorage.getItem('research-user-data'))
                        let responsUpdate = await UserService.updateUser(user.id, { discordId })
                        if(responsUpdate.statusCode >= 400) {
                            toast.error(responsUpdate.data.message || responsUpdate.data.error)
                        } else {
                            await LoadPermission(discordId)
                            toast.success('Discord vinculado com sucesso. Atualize a pagina para carregar suas novas funcionalidades ')
                            localStorage.removeItem('discId')
                        }
                    }
                }
            } catch (error) {
                console.log(error)
                navigate('/')
            }

        }
        fetchData()

    }, [updatedPage])

    // useEffect(() => {
    //     let discordId = localStorage.getItem('discId')
    //     if (discordId) {
    //         toast.warning('Faça login para vincular sua conta ao discord')
    //         navigate('/login')
    //     }else{}
    // },[])
    return (
        <div className={styles.container}>
            <div >
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Long Short Ratio
                    </Text>
                    <Text
                        fontSize='14px'
                        color='white'
                        margin='10px'>
                        LSR Global:  <b>{lsrGlobal.toFixed(2)}</b>
                    </Text>
                    {/* <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}> */}
                    <NotificationSongGlobal />
                    <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                    {/* <Timer
                                loadData={data}
                                handleUpdateTable={setTimer}
                                statusTimer={timer}
                                actionUpdateTable={setUpdateTable} /> */}
                    {/* </Flex> */}

                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson
                                columns={columns} symbols={symbols} dataTable={data}
                                handleUpdateSymbols={updateSymbols} updateTable={updateTable}
                                order={order} handleFilterTable={handleFilterTable} filtersApplieds={filterApplied} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default Home;