import { api, requestConfig } from "../utils/config";


const getAlertStatus = async (name) => {
    const config = requestConfig("GET", null);
    try {
        const res = await fetch(`${api}/alertStatus${name ? '/' + name : ''}`, config)
            .then((res) => {
                const statusCode = res.status;
                return res.json().then((obj) => ({
                    statusCode: statusCode,
                    data: obj,
                }));
            })
            .catch((err) => ({
                errors: ["Houve um erro, por favor tente mais tarde."],
            }));

        return res;
    } catch (error) {
        console.log(error);
    }
};

// Create a strategie
const createAlertStatus = async (data) => {
    const config = requestConfig("POST", data);
    try {
        const res = fetch(`${api}/alertStatus`, config)
            .then((res) => {
                const statusCode = res.status;
                return res.json().then((obj) => ({
                    statusCode: statusCode,
                    data: obj,
                }));
            })
            .catch((err) => {
                alert(err)
                return {
                    errors: ["Houve um erro, por favor tente mais tarde."],
                }
            });

        return res;
    } catch (error) {
        console.log(error);
    }
};

// Update alert status
const updateAlertStatus = async (data, platform) => {
    const config = requestConfig("PUT", data);

    try {
        const res = await fetch(`${api}/alertStatus/${platform}`, config)
            .then(res => {
                const statusCode = res.status;
                return res.json().then((obj) => ({
                    statusCode: statusCode,
                    data: obj,
                }));
            })
            .catch((err) => ({
                errors: ["Houve um erro, por favor tente mais tarde."],
            }));

        return res;
    } catch (error) {
        console.log(error);
    }
};

const alertsStatus = {
    //   deleteStrategie,
    getAlertStatus,
    createAlertStatus,
    updateAlertStatus
};

export default alertsStatus;
