import { 
    Box, 
    Button, 
    Flex, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Text, 
    useDisclosure
} from "@chakra-ui/react";
import backgroundImageLogin from '../../assets/dashboard-login.png'
import { FcGoogle } from 'react-icons/fc'
import {Buffer} from 'buffer'

import { AuthContext } from '../../hooks/Auth/index'
import authService from '../../services/authService'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { toast } from "react-toastify";
import { PermissionContext } from "../../hooks/Permission";
import CheckModal from "./CheckModal";

const Login = () => {
    const params = useParams()
    const [openCheckModal, setOpenCheckModal] = useState(false);
    const [emailToRecoverPassword, setEmailToRecoverPassword] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingEnter, setLoadingEnter] = useState(false);
    let discordIdTemp = localStorage.getItem('discId')
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const { AuthLogin, setAuthToken } = useContext(AuthContext)
    const { LoadPermission } = useContext(PermissionContext)
    const navigate = useNavigate();

    useEffect(() => {
        if(params.id) {
            let buff = new Buffer(params.id, 'base64');
            let text = buff.toString('ascii');
            localStorage.setItem('discId', text)
            navigate('/longShortRatio')
        }
    },[])

    function generatePassRandom(n) {
        const randomBytes = new Uint8Array(n);
        window.crypto.getRandomValues(randomBytes);
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let result = "";
        randomBytes.forEach(byte => {
            result += characters.charAt(byte % charactersLength);
        });
        return result;
    }

    function handleGoogleSignin() {
        AuthLogin((responseSucess) => {
            setLoading(true)
            setTimeout(async () => {
                try {
                    let userCreated = await authService.registerUser({
                        email: responseSucess.email,
                        password: generatePassRandom(8),
                        username: responseSucess.username,
                        photo: responseSucess.photo,
                        token: responseSucess.token,
                        discordId: localStorage.getItem('discId')
                    })
                    console.log('userCreated')
                    console.log(userCreated)
                    if (userCreated && userCreated.statusCode === 201 || userCreated.statusCode === 200) {
                        localStorage.setItem('token', userCreated.token)
                        localStorage.setItem('research-user-data', JSON.stringify(userCreated.user))
                        // let permissions = []0
                        console.log('ESTOU AQUI ')
                        console.log('userCreated')
                        console.log(userCreated)
                        if(userCreated.user.discordId){
                            console.log('Carregando as permissões')
                            await LoadPermission(userCreated.user.discordId)
                        }
                        console.log('Passei da verificação de permissões')
                        setAuthToken(userCreated.token)
                        setLoading(false)
                        // window.history.go('/longShortRatio')
                        navigate('/longShortRatio')
                    } else {
                        setLoading(false)
                        toast.error(userCreated.error)
                        triggerError(userCreated.message)
                    }
                } catch (error) {
                    console.log('error')
                    console.log(error)
                    setLoading(false)
                    // toast.error(error.message)
                    // triggerError(error.message)
                }
                
            }, 2000)
        }, triggerError)
    }

    async function handleClickInLogin() {
        setLoadingEnter(true)
        let userCreated = await authService.userLogin({
            email: data.email.trim(),
            password: data.password,
            discordId: localStorage.getItem('discId')
        })
 
        if (userCreated.statusCode === 201 || userCreated.statusCode === 200) {

            localStorage.setItem('token', userCreated.token)
            localStorage.setItem('research-user-data', JSON.stringify(userCreated.user))
            let permissions = []
            Object.entries(userCreated.permissions).map(permission => {
                if (permission[1]) {
                    permissions.push({ [permission[0]]: permission[1]})
                }
            })
            localStorage.setItem('permissions', JSON.stringify(permissions))
            setAuthToken(userCreated.token)
            // window.history.go('/longShortRatio')
            toast.success(userCreated.message)
            setLoading(false)
            setLoadingEnter(false)
            localStorage.removeItem('discId')
            navigate('/longShortRatio')
        } else {
            if(userCreated.statusCode === 403){
                console.log('openCheckModal')
                console.log(openCheckModal)
                setOpenCheckModal(true)
                // setLoading(false)
                // toast.error(userCreated.response || userCreated.error)
            } else{
                console.log('userCreated')
                console.log(userCreated)
                setLoading(false)
                toast.error(userCreated.response || userCreated.error)
                if (localStorage.getItem('discId')) {
                    localStorage.removeItem('discId')
                }

            }
            setLoadingEnter(false)
        }
    }

    function triggerError(err) {
        setLoading(false)
        toast.error(err)
    }

    async function handelClickForgotPassword() {

        if (!emailToRecoverPassword)
            toast.error('Por favor preencha o campo de email. ')

        setLoadingModal(true)
        let response = await authService.sendEmailForgotPasswordCode({ email: emailToRecoverPassword })

        if (response.statusCode === 201 || response.statusCode === 200) {
            toast.success('Email enviado com sucesso. ')
            onClose()
        } else {
            setLoading(false)
            toast.error(response.message || response.error)
        }
        setLoadingModal(false)
    }
    if(params.id) {
        let buff = new Buffer(params.id, 'base64');
        let text = buff.toString('ascii');
        localStorage.setItem('discId', text)
        navigate('/longShortRatio')
    }
    return (
        <Box maxW={'1920px'} h={'100vh'} bg={'#000000'}>
            <Box h={'90%'} backgroundImage={`url(${backgroundImageLogin})`} backgroundPosition={'100% 35%'} margin={'auto'}>
                <Flex
                    // bg={"red"}
                    height={'100%'}
                    flexDirection={'column'}>
                    <Box maxW={'95rem'} h={'148px'} alignItems={'center'} margin={'auto'}>
                        <Text
                            color={'white'}
                            fontSize={'42px'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                            marginTop={'35px'}
                        >
                           {discordIdTemp ? 'VINCULE SEU DISCORD ID' : 'BELO TRADE RESEARCH'}
                        </Text>
                        <Box maxW={'95rem'} h={'59px'} alignItems={'center'}>
                            <Text
                                color={'white'}
                                fontSize={'16px'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                marginTop={'15px'}
                            >
                                {discordIdTemp 
                                    ? <Text
                                        color={'white'}
                                        fontSize={'16px'}
                                        textAlign={'center'}
                                        fontWeight={'bold'}
                                        marginTop={'15px'}
                                        > 
                                        FAÇA LOGIN OU CRIE UMA NOVA CONTA PARA VINCULAR AO SEU DISCORD ID 
                                    </Text>
                                    : <Text
                                        color={'white'}
                                        fontSize={'16px'}
                                        textAlign={'center'}
                                        fontWeight={'bold'}
                                        marginTop={'15px'}
                                        >
                                            TODAS  AS  INFORMAÇÕES  PARA  UM  <i>CRYPTO TRADER</i> &nbsp;EM  UM  SO  LUGAR 
                                        </Text>}
                                
                            </Text>
                        </Box>
                    </Box>

                    <Flex
                        alignItems={'center'}
                        flexDirection={'column'}
                        margin={'auto'}>
                        <Text
                            fontFamily={'Poppins'}
                            marginBottom={'30px'}
                            align={'center'}
                            fontSize={'32px'}
                            color={'white'}>
                            Login
                        </Text>
                        <Input
                            type={'text'}
                            placeholder={'Seu e-mail'}
                            // h={ }
                            w={'300px'}
                            color={'white'}
                            borderColor={'white'}
                            onChange={(e) => {
                                setData({ ...data, email: e.target.value })
                            }}
                        />
                        <Input
                            mt={'15px'}
                            type={'password'}
                            placeholder={'Senha'}
                            color={'white'}
                            w={'300px'}
                            borderColor={'white'}
                            onChange={(e) => {
                                setData({ ...data, password: e.target.value })
                            }}
                        />
                        <Text
                            w={'100%'}
                            color={'white'}
                            fontSize={'12px'}
                            mt={'15px'}
                            cursor={'pointer'}
                            onClick={onOpen}
                            _hover={{ color: 'activeGreen' }}
                        >
                            Esqueci minha senha
                        </Text>
                        <Box
                            w={'100%'}
                            align={'end'}>
                            <Button
                                mr={'0px'}
                                mt={'5px'}
                                w={'95px'}
                                mb={'10px'}
                                variant='outline'
                                color={'white'}
                                _hover={{ color: 'activeGreen', boxShadow: '1px 1px gray' }}
                                onClick={handleClickInLogin}
                            >
                                {loadingEnter ? <Spinner /> : 'ENTRAR'}
                            </Button>
                        </Box>
                        <Link to={'/register'}>
                            <Text
                                color={'white'}
                                fontSize={'14px'}
                                _hover={{ color: 'activeGreen' }}>
                                Cadastre-se gratuitamente
                            </Text>
                        </Link>
                        <Button
                            w={'240px'}
                            cursor={'pointer'}
                            fontSize={'14px'}
                            mt={'15px'}
                            align={'center'}
                            _hover={{ color: 'activeGreen' }}
                            onClick={handleGoogleSignin}>

                            {loading ? <Spinner /> :
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}>
                                    <FcGoogle size={'25px'} />
                                    <Text>Sign with Google</Text>
                                </Box>}

                        </Button>
                    </Flex>
                </Flex>
            </Box > 
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent width={'300px'} bg={'primary'} border={'1px solid white'} >
                    <ModalHeader color={'white'}>Esqueceu a senha ?</ModalHeader>
                    <ModalCloseButton color={'white'}/>
                    <ModalBody>
                        <Text color={'white'}>Digite seu email: </Text>
                        <Input
                            mt={'15px'}
                            type={'email'}
                            placeholder={'Email'}
                            color={'white'}
                            borderColor={'white'}
                            onChange={(e) => {
                                setEmailToRecoverPassword(e.target.value)
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Flex>
                            {/* <Button colorScheme='blue' mr={3} onClick={() => clearConfig()}> */}
                            <Button colorScheme='blue' mr={3}  onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button color={'black'} onClick={handelClickForgotPassword}>
                                {loadingModal ? <Spinner /> : 'ENVIAR'}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal >
            <CheckModal open={openCheckModal} close={setOpenCheckModal}/>
        </Box >
    );

}

export default Login;