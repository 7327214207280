import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import PrivateRoutes from "./private.routes";
import Home from "../pages/Home/Home";
import OpenInterest from "../pages/OpenInterest/OpenInterest";
import MarketVolume from "../pages/MarketVolume/MarketVolume";
import Fuding from "../pages/Fuding/Fuding";
import Strategies from "../pages/Strategies/Strategies";
import Liquidation from "../pages/Liquidations/Liquidations";
import OrderBookDeep from "../pages/OrderBookDeep/OrderBookDeep";
import Radar from "../pages/Radar/Radar";
import Marketcap from "../pages/Marketcap/Marketcap";
import Trdr from "../pages/Trdr/Trdr";
import Tradingview from "../pages/Tradingview/Tradingview";
import StrategieAlerts from "../pages/StrategieAlerts/StrategieAlerts";
import Macd from "../pages/Macd/Macd";
import Rsi from "../pages/Rsi/Rsi";
import Profile from "../pages/Profile";
import Verification from "../pages/Verification/Verification";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Admin from "../pages/Admin/Admin";
import LinkDiscord from "../pages/LinkDiscord/LinkDiscord";

const AppRoutes = () => {
    return (
        <Routes>
            {/* rotas publicas */}
            {/* <Route path='/login' element={<Login />} /> */}
            <Route path='/login/:id' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/verification/:code' element={<Verification />} />
            <Route path='/forgotPassword/:code' element={<ForgotPassword />} />

            {/* rotas privadas */}
            <Route path='/' element={<PrivateRoutes />}>
                <Route path='/' element={<Home />} />
            </Route>
            <Route path='/longShortRatio' element={<PrivateRoutes />}>
                <Route path='/longShortRatio' element={<Home />} />
            </Route>
            <Route path='/discord' element={<PrivateRoutes />}>
                <Route path='/discord' element={<LinkDiscord />} />
            </Route>
            <Route path='/admin' element={<PrivateRoutes />}>
                <Route path='/admin' element={<Admin />} />
            </Route>
            <Route path='/profile' element={<PrivateRoutes />}>
                <Route path='/profile' element={<Profile />} />
            </Route>
            <Route path='/openInterest' element={<PrivateRoutes />}>
                <Route path='/openInterest' element={<OpenInterest />} />
            </Route>
            <Route path='/marketVolume' element={<PrivateRoutes />}>
                <Route path='/marketVolume' element={<MarketVolume />} />
            </Route>
            <Route path='/funding' element={<PrivateRoutes />}>
                <Route path='/funding' element={<Fuding />} />
            </Route>
            <Route path='/strategies' element={<PrivateRoutes />}>
                <Route path='/strategies' element={<Strategies />} />
            </Route>
            <Route path='/liquidations' element={<PrivateRoutes />}>
                <Route path='/liquidations' element={<Liquidation />} />
            </Route>
            <Route path='/orderBookDeep' element={<PrivateRoutes />}>
                <Route path='/orderBookDeep' element={<OrderBookDeep />} />
            </Route>
            <Route path='/radar' element={<PrivateRoutes />}>
                <Route path='/radar' element={<Radar />} />
            </Route>
            <Route path='/marketcap' element={<PrivateRoutes />}>
                <Route path='/marketcap' element={<Marketcap />} />
            </Route>
            <Route path='/trdr' element={<PrivateRoutes />}>
                <Route path='/trdr' element={<Trdr />} />
            </Route>
            <Route path='/tradingview' element={<PrivateRoutes />}>
                <Route path='/tradingview' element={<Tradingview />} />
            </Route>
            <Route path='/alert-strategie' element={<PrivateRoutes />}>
                <Route path='/alert-strategie' element={<StrategieAlerts />} />
            </Route>
            <Route path='/macd' element={<PrivateRoutes />}>
                <Route path='/macd' element={<Macd />} />
            </Route>
            <Route path='/rsi' element={<PrivateRoutes />}>
                <Route path='/rsi' element={<Rsi />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;