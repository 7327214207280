// import { Flex, Text } from "@chakra-ui/react";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { getRsi } from '../../services/rsi'
// import TablePerson from "./Table/Table";
import EmBreve from '../../components/EmBreve/Embreve'
import styles from './Rsi.module.css'

const Rsi = () => {

    return (
        <div className={styles.container}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70%'

            }}>
                <EmBreve />
            </div>
        </div>
    )

    // const columnsFix = {
    //     'Symbols': false,
    //     '15m': false,
    //     '1h': false,
    //     '4h': false,
    //     // '24h': false,
    // }

    // const [data, setData] = useState([])
    // const [columns, setColumns] = useState(columnsFix)


    // async function getData(symbolsData, period) {
    //     const url = 'https://fapi.binance.com/futures/data/takerlongshortRatio'
    //     let newDatas = {}
    //     symbolsData.map(async (symbol) => {
    //         axios.get(
    //             `${url}?symbol=${symbol.toUpperCase()}USDT&period=${period}`
    //         ).then((resp) => {
    //             newDatas[symbol] = resp.data
    //         }).catch(err => console.log(err))
    //     })

    //     return { [period]: newDatas }
    // }

    // // function prepareData(data15mApi, data1hApi, data4hApi, symbolsData) {
    // //     let newData = {
    // //         '15m': {},
    // //         '1h': {},
    // //         '4h': {},
    // //         '24h': {},
    // //     }
    // //     symbolsData.map((symbol) => {
    // //         let data15m = (parseFloat(data15mApi['15m'][symbol].at(-2)['buySellRatio']) - 1) * 100
    // //         let data1h = (parseFloat(data1hApi['1h'][symbol].at(-2)['buySellRatio']) - 1) * 100
    // //         let data4h = (parseFloat(data4hApi['4h'][symbol].at(-2)['buySellRatio'] - 1)) * 100

    // //         newData['15m'] = { ...newData['15m'], [symbol]: data15m }
    // //         newData['1h'] = { ...newData['1h'], [symbol]: data1h }
    // //         newData['4h'] = { ...newData['4h'], [symbol]: data4h }
    // //         // newData['24h'] = { ...newData['24h'], [symbol]: data1d }
    // //     })
    // //     setUpdateTable(true)
    // //     return newData
    // // }

    // useEffect(() => {
    //     async function fetchData() {

    //         axios.get('https://fapi.binance.com/fapi/v1/ticker/price')
    //             .then(async (resp) => {
    //                 const correctSymbols = resp.data
    //                 console.log('correctSymbols')
    //                 console.log(correctSymbols)
    //                 let newData = {}
    //                 let periods = Object.keys(columnsFix).slice(1)
    //                 correctSymbols.map(s => {
    //                     periods.map(async (p) => {
    //                         newData[s][p] = await getRsi(s, p)
    //                     })
    //                 })
    //                 console.log('newData')
    //                 console.log(newData)
    //             })
    //             .catch(err => console.log(err))
    //         setData([
    //             { symbols: ['ETH/USDT'] },
    //             { '15m': ['30'] },
    //             { '1h': ['35'] },
    //             { '4h': ['40'] }
    //         ])
    //     }
    //     fetchData()
    // }, [])



    // return (
    //     <div className={styles.container}>
    //         <div>
    //             <Flex flexDirection={'column'} alignItems={'center'}>
    //                 <Text
    //                     fontSize='32px'
    //                     color='white'
    //                     fontWeight='bolder'
    //                     margin='5px'>
    //                     RSI
    //                 </Text>
    //                 <Flex
    //                     maxHeight={'78vh'}
    //                     maxWidth={'80%'}
    //                     borderRadius={'30px'}
    //                     padding={'0px 0px'}
    //                     flexDirection={'column'}
    //                     boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
    //                     transition={'1s'}>
    //                     <div className={styles.tableContainer}>
    //                         <TablePerson columns={columns} dataTable={data} />
    //                     </div>
    //                 </Flex>

    //             </Flex>
    //         </div>
    //     </div>

    //     );
}

export default Rsi;