import { api, requestConfig } from "../utils/config";


// Get Historic Strategie
const getHistoricStrategies = async (name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(`${api}/alertStrategie/${name ? '/' + name : ''}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const historicStrategiesService = {
  getHistoricStrategies
};

export default historicStrategiesService;
