import EmBreve from "../../components/EmBreve/Embreve";
import styles from './Marketcap.module.css'

const Marketcap = () => {
    return (
        <div className={styles.container}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70%'

            }}>
                <EmBreve />
            </div>
        </div>
    );
}

export default Marketcap;