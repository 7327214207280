import { Flex, Grid, GridItem, Table, Text } from '@chakra-ui/react'
import { TableContainer, Tbody, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp, FiTrash } from 'react-icons/fi';
import styles from './Table.module.css'
import { getMaskedValue } from '../../../../utils/maskValue';
import Filter from '../../../../components/Filter/Filter';
import { useContext } from 'react';
import { FilterContext } from '../../../../hooks/Filter/Filter';
import Td from '../../../../components/TableComponents/Td';
// import { unmaskValue } from '../../../../utils/unmaskValue';

const TablePerson = ({ columns, dataTable, order, handleUpdateSymbols, deleteColumn }) => {

    const { filterData } = useContext(FilterContext)

    function changeColor(value) {
        if (parseFloat(value) > 0)
            return '#22C55E'
        else if (parseFloat(value) < 0)
            return '#EC4889'
        else
            return '#FFFFFF'
    }

    function getRow(column, symbol) {
        if (column.toUpperCase() === 'SÍMBOLO') {
            return (
                <Td w={'120px'}>
                    <Flex>
                        <img src={`images/${symbol}.png`} width={18} height={18} />
                        &nbsp; &nbsp;
                        {symbol}
                    </Flex>
                </Td>
            )
        } else if (column.toUpperCase() === 'LSR') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['LSR'][symbol]))}>
                        {dataTable['LSR'][symbol]}
                    </Flex>
                </Td>
            )
        } else if (column === 'LSR:15m') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['LSR:15m'][symbol]))}>
                        {parseFloat(dataTable['LSR:15m'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['LSR:15m'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'LSR:1h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['LSR:1h'][symbol]))}>
                        {parseFloat(dataTable['LSR:1h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['LSR:1h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'LSR:4h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['LSR:4h'][symbol]))}>
                        {parseFloat(dataTable['LSR:4h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['LSR:4h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'LSR:24h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['LSR:24h'][symbol]))}>
                        {parseFloat(dataTable['LSR:24h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['LSR:24h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'OI') {
            return (
                <Td w={'120px'}>
                    {getMaskedValue(dataTable['OI'][symbol])}
                </Td>
            )
        } else if (column === 'OI:15m') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['OI:15m'][symbol]))}>
                        {parseFloat(dataTable['OI:15m'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['OI:15m'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'OI:1h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['OI:1h'][symbol]))}>
                        {parseFloat(dataTable['OI:1h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['OI:1h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'OI:4h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['OI:4h'][symbol]))}>
                        {parseFloat(dataTable['OI:4h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['OI:4h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'OI:24h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['OI:24h'][symbol]))}>
                        {parseFloat(dataTable['OI:24h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['OI:24h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'MV:15m') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['MV:15m'][symbol]))}>
                        {parseFloat(dataTable['MV:15m'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['MV:15m'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'MV:1h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['MV:1h'][symbol]))}>
                        {parseFloat(dataTable['MV:1h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['MV:1h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'MV:4h') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['MV:4h'][symbol]))}>
                        {parseFloat(dataTable['MV:4h'][symbol]) > 0 ? '+' : ''}
                        {parseFloat(dataTable['MV:4h'][symbol]).toFixed(2)}%
                    </Flex>
                </Td>
            )
        } else if (column === 'FR') {
            return (
                <Td w={'120px'}>
                    <Flex color={changeColor(parseFloat(dataTable['FR'][symbol]))}>
                        {parseFloat(dataTable['FR'][symbol]) > 0 ? '+' : ''}
                        {dataTable['FR'][symbol]}%
                    </Flex>
                </Td>
            )
        }
    }

    function DataOrderingByColumn(column) {
        if (column.toUpperCase() === 'SÍMBOLO') {
            if (order) {
                filterData.symbols = filterData?.symbols.sort()
                handleUpdateSymbols(filterData, column, columns)
            } else {
                filterData.symbols = filterData.symbols.reverse()
                handleUpdateSymbols(filterData, column, columns)
            }
        } else {
            if (order) {
                filterData.symbols = filterData.symbols.sort((a, b) => {
                    if (parseFloat(filterData[column][a]) > parseFloat(filterData[column][b]))
                        return 1
                    if (parseFloat(filterData[column][a]) < parseFloat(filterData[column][b]))
                        return -1
                })
                handleUpdateSymbols(filterData, column, columns)
            } else {
                filterData.symbols = filterData.symbols.reverse()
                handleUpdateSymbols(filterData, column, columns)
            }
        }
    }

    function deleteColumns(column) {
        delete columns[column]
        deleteColumn(columns, column)
    }

    return (<>

        <TableContainer borderRadius={30} overflowX={'hidden'}>
            <Table variant={'unstyled'} textColor={'text'} maxW={'600px'} >
                <Thead bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map((th, index) => (
                            // onClick={() => { DataOrderingByColumn(th) }}
                            <Th key={th}
                                textColor={'text'}
                                _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                                cursor='pointer'
                                p={'6px'}
                                minWidth={'120px'}
                                maxWidth={'120px'}
                                boxSizing={'border-box'}
                            >
                                <Grid
                                    alignContent={'center'}
                                    templateColumns='repeat(5, 2fr)'
                                    gap={0.1}
                                    justifyItems={'center'}
                                >
                                    <GridItem w='100%' h='10' onClick={() => { DataOrderingByColumn(th) }}></GridItem>
                                    <GridItem w='100%' h='10' display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={() => { DataOrderingByColumn(th) }}>
                                        <span className={styles.valueTable}>{th}</span>
                                    </GridItem>
                                    <GridItem
                                        w='100%'
                                        h='10'
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        padding={'0'}
                                        onClick={() => { DataOrderingByColumn(th) }}>
                                        {!columns[th] ?
                                            <div className={styles.changeColor}>
                                                <FiArrowDown
                                                    fontSize='18px'
                                                    cursor='pointer'
                                                    transition='0.2s'
                                                    margin=' 0px 2px'
                                                    z-index='200'
                                                />
                                            </div>
                                            : <div className={styles.changeColor}>
                                                <FiArrowUp
                                                    fontSize='18px'
                                                    cursor='pointer'
                                                    transition='0.2s'
                                                    margin=' 0px 2px'
                                                    z-index='200'
                                                />
                                            </div>}
                                    </GridItem>
                                    <GridItem w='18px' h='5' display={'flex'} alignItems={'center'} justifyContent={'center'} marginY={'10px'}>
                                        <div className={styles.changeColor}>
                                            <Filter column={th} />
                                        </div>
                                    </GridItem>
                                    <GridItem w='18px' h='5' display={'flex'} alignItems={'center'} justifyContent={'center'} marginY={'10px'}>
                                        {index !== 0 ? (
                                            <div className={styles.changeColor}>
                                                <FiTrash
                                                    fontSize='18px'
                                                    cursor='pointer'
                                                    transition='0.2s'
                                                    z-index='200'
                                                    onClick={() => deleteColumns(th)}
                                                />
                                            </div>
                                        ) : (<> </>)}
                                    </GridItem>
                                </Grid>

                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody overflowX={'auto'} overflowY={'auto'} maxHeight={'410px'} display={'block'}>
                    {filterData && filterData.symbols ?
                        filterData.symbols.map(((row, index) => {
                            return <Tr key={index}>
                                {Object.keys(columns).map(clm => (getRow(clm, row)))}
                            </Tr>
                        })) : (
                            <Tr key={Date.now()}> </Tr>)}
                </Tbody>
                <Tfoot bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            <Th key={th}></Th>
                        ))}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer >
    </>
    );
}

export default TablePerson;