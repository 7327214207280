import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
// import EmBreve from "../../components/EmBreve/Embreve";
import TablePerson from "./Table/Table";
import alerts from '../../services/alerts'
import styles from './Tradingview.module.css'
import openSocket from "../../services/socket-io";
import NotificationSong from "../../components/NotificationSong/NotificationSong";


const Tradingview = () => {

    const columnsFix = {
        'moeda': false,
        'nome': false,
        'hora': false,
        'preço': false
        // 'Preço': false
    }

    const [data, setData] = useState([])
    const [columns, setColumns] = useState(columnsFix)
    const [alert, setAlert] = useState(false)


    function getAlerts() {
        alerts.getAlertsTradingView()
            .then(resp => {
                let responseApi = resp.data
                setData(responseApi)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getAlerts()
    }, [])

    useEffect(() => {
        const socket = openSocket();

        socket.emit('client-conected', () => {
            console.log('Client connected. ')
        })

        socket.on('alertTradingview', (alrt) => {
            if (!data.length) {
                setAlert(prev => !prev)
                getAlerts()
            }
        })

        return () => {
            socket.disconnect();
        };
    }, [])



    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='5px'>
                        EQUIPE BELO TRADE
                    </Text>
                    {/* <Stack width='65%' align={'end'} marginBottom={'5px'}>
                        <NotificationSong trigger={alert} />
                    </Stack > */}
                    <NotificationSong trigger={alert} platform={'tradingView'} />
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} dataTable={data} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default Tradingview;