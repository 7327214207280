import { Box, Flex, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import styles from './Admin.module.css'
import TablePerson from "./Components/Table/Table";
import { useEffect } from "react";
import userService from "../../services/userService";
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Admin = () => {
    const [data, setData] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [filterEmail, setFilterEmail] = useState('')
    const [userStatistics, setUserStatistics] = useState({
        userQuantity: 0,
        userQuantityChecked:0,
        userQuantitySenior: 0,
        userQuantityCommon: 0
    })
    const columns = ['username', 'email', 'verificado', 'tipo', 'Discord', 'ações']

    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            let response = await userService.getUsers()
            if (response.statusCode > 400) {
                toast.error(response.data.message || response.message)
                navigate('/')
            } else {
                let countUserSenior = response['data']['users'].filter(u=> u.type === 'senior')
                let countUserCommon = response['data']['users'].filter(u=> u.type === 'common')
                let countUserChecked = response['data']['users'].filter(u=> u.checked)

                setData(response['data']['users'])
                setDataFilter(response['data']['users'])
                setUserStatistics({
                    userQuantity: response['data']['users'].length,
                    userQuantityChecked:countUserChecked.length,
                    userQuantityCommon: countUserCommon.length,
                    userQuantitySenior: countUserSenior.length
                })
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        if(!data.length) return
        let newFilter = data.filter(u => 
            u.email.startsWith(filterEmail) || 
            u.username.startsWith(filterEmail) || 
            (u.discordId && u.discordId.startsWith(filterEmail))
        )
        setDataFilter(newFilter)
    }, [filterEmail])



    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text

                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Gestão de usuários
                        </Text>
                    <Flex flexDirection={'column'}>
                        <Text
                            align={'center'}
                            fontSize='14px'
                            color='white'
                            margin='5px'>
                            Quantidade de usuários: <b>{userStatistics.userQuantity}</b> &nbsp; | &nbsp;
                            Usuários verificado: <b>{userStatistics.userQuantityChecked}</b>
                        </Text>
                        <Text
                            align={'center'}
                            fontSize='14px'
                            color='white'
                            margin='5px'>
                            Usuários comuns: <b>{userStatistics.userQuantityCommon}</b> &nbsp; | &nbsp;
                            Usuários seniores: <b>{`${userStatistics.userQuantitySenior}`}</b>
                        </Text>
                        <NotificationSongGlobal />
                        {/* <GenericTimer lastUpdatedAt={lastUpdatedAt} /> */}
                        {/* <div>
                            <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                                <Timer
                                    loadData={data}
                                    handleUpdateTable={setTimer}
                                    statusTimer={timer}
                                    actionUpdateTable={setUpdateTable} />
                            </Flex>
                        </div> */}

                    <Flex w={'870px'} flexDirection={'column'}>
                        <Text color={'white'} marginBottom={'5px'}> Filtrar usuários: </Text>
                        <Input
                            w={'50%'}
                            onChange={(e) => setFilterEmail(e.target.value)}
                            placeholder="Username, Email ou DiscordId"
                            type="text"
                            color={'white'}
                            marginBottom={'10px'} 
                            />
                       
                            {/* <Box>
                                <HiOutlineMagnifyingGlass  color="white" size={'30px'} />
                            </Box> */}
                    </Flex>
                    </Flex>
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson 
                                columns={columns}
                                dataTable={dataFilter}
                            />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>
    );
}
 
export default Admin;