import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";



const ModalDeleteUser = ({ name, icon, handleDeleteUser, id }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    function handleDelete() {
        handleDeleteUser(id)
        onClose() 
    }

    return (<>
        <span onClick={onOpen}>
            <Flex>
                {icon}
                <Text marginLeft={'5px'}>{name}</Text>
            </Flex>
        </span>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent width={'450px'}>
                <ModalHeader>Excluir usuário</ModalHeader>
                <ModalBody>
                    <span style={{fontWeight:'bolder'}}>ATENÇÃO</span>: Você está prestes a excluir um usuário. Esta ação é irreversível e todos os 
                    dados associados a este usuário serão apagados permanentemente do sistema. Certifique-se 
                    de que você realmente deseja realizar essa ação antes de continuar.
                </ModalBody>
                <ModalCloseButton />
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button variant='ghost' onClick={() => handleDelete()}>
                        Excluir
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>);
}

export default ModalDeleteUser;