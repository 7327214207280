import { api, requestConfig } from "../utils/config";


// Create a strategie
const createStrategie = async (data) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("POST", data, token);
  try {
    const res = fetch(`${api}/strategies`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => {
        alert(err)
        return {
          errors: ["Houve um erro, por favor tente mais tarde."],
        }
      });

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Update a strategie
const updateStrategie = async (data, name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("PUT", data, token);

  try {
    const res = await fetch(`${api}/strategies/${name}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Delete a strategie
const deleteStrategie = async (name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("DELETE", null, token);

  try {
    const res = await fetch(`${api}/strategies/${name}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};
// Delete a strategie
const getStrategies = async (name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(`${api}/strategies${name ? '/' + name : ''}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const userService = {
  createStrategie,
  updateStrategie,
  deleteStrategie,
  getStrategies
};

export default userService;
