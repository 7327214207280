import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FilterContext } from "../../../../hooks/Filter/Filter";

const ModalNewStrategie = ({ name, icon, action, setNameStrategie, isCreate, resetTable }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [nameInput, setNameInput] = useState('')
    const { clearFilter } = useContext(FilterContext)
    function handleAction() {
        if (!isCreate)
            action(nameInput)
        else
            action({ name: nameInput }, nameInput)
        setNameStrategie(nameInput)
        if (resetTable)
            resetTable()
        clearFilter()
        onClose()
    }

    return (<>
        <span onClick={onOpen}>
            <Flex>
                {icon}
                <Text marginLeft={'5px'}>{name}</Text>
            </Flex>
        </span>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent width={'250px'}>
                <ModalHeader>Criar radar</ModalHeader>
                <ModalCloseButton />
                <ModalBody align={'center'}>
                    <Text align={'start'}>Nome</Text>
                    <Input width={'200px'} onChange={(e) => { setNameInput(e.target.value) }} value={nameInput} />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button variant='ghost' onClick={() => handleAction()}>
                        {isCreate ? 'Criar' : 'Atualizar'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>);
}

export default ModalNewStrategie;