import { api, requestConfig } from "../utils/config";


const saveTableData = async (data) => {
  const config = requestConfig("POST", data);
  try {
    const res = fetch(`${api}/dataTable`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => {
        return {
          errors: ["Houve um erro, por favor tente mais tarde."],
        }
      });

    return res;
  } catch (error) {
    console.log(error);
  }
};

const getTableData = async (name, timing) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(`${api}/dataTable${name ? '/' + name : ''}${timing ? '/' + timing : ''}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const syncSymbols = async () => {
  const config = requestConfig("GET", null);
  try {
    const res = await fetch(`${api}/dataTable/syncSymbols/`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const getSymbols = async () => {
  const config = requestConfig("GET", null);
  try {
    const res = await fetch(`${api}/dataTable/symbols/`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const TableData = {
  saveTableData,
  getTableData,
  syncSymbols,
  getSymbols
};

export default TableData;
