import { api, requestConfig } from "../utils/config";


// Create a user
const registerUser = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        console.log('err')
        console.log(err)
        return {
            error: err.message,
        }
    }
};

const checkVerificationCode = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth/check-verification-code`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const checkForgotPasswordCode = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth/check-forgot-password`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const sendEmailForgotPasswordCode = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth/sendEmail-forgot-password`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const resetPassword = async (data) => {
    try {
        const config = requestConfig("PUT", data);
        const res = await fetch(`${api}/auth/reset-password`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const resendVerificationCode = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth/resend-verification-code`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const userLogin = async (data) => {
    try {
        const config = requestConfig("POST", data);
        const res = await fetch(`${api}/auth/login`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const logout = async (data) => {
    try {
        let token = localStorage.getItem('token')
        const config = requestConfig("GET", null, token);
        const res = await fetch(`${api}/auth/logout`, config)
        let response = await res.json()
        response.statusCode = res.status
        return response;
    } catch (err) {
        return {
            errors: ["Houve um erro, por favor tente mais tarde."],
        }
    }
};

const authService = {
    registerUser,
    checkVerificationCode,
    resendVerificationCode,
    userLogin,
    checkForgotPasswordCode,
    sendEmailForgotPasswordCode,
    resetPassword,
    logout
};

export default authService;
