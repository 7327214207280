import {
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { IoDuplicateSharp, IoSaveSharp } from 'react-icons/io5'
import { SlPencil } from 'react-icons/sl'
import { useState } from "react";
import { FiDelete } from "react-icons/fi";
import ModalNewStrategie from "../ModalNewStrategie/ModalNewStrategie";
import { useEffect } from "react";
import ModalDeleteStrategie from "../ModalDeleteStrategie/ModalDeleteStrategie";
// import ModalIndicators from "../ModalIndicators/ModalIndicators";

const MenuActions = ({ handleSave, handleUpdate, handleName, handleRemove, name, resetTable, updating }) => {
    const [nameStrategies, setNameStrategies] = useState(name)
    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        handleName(nameStrategies)
    }, [nameStrategies])

    useEffect(() => {
        setUpdated(updating)
    }, [updating])
    console.log('updating')
    console.log(updating)
    return (
        <>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    bg={updating ? 'orange' : 'header'}
                    marginTop={'5px'}
                    marginBottom={'5px'}
                    marginRight={'10px'}
                    // onClick={onOpen}
                    color='white'
                    _hover={{ bg: 'secondary', color: 'activeGreen' }}
                    _active={{
                        backgroundColor: 'heade'
                    }}
                >
                    {name ? name.length > 11 ? name.slice(0, 13) + '...' : name : 'Novo modelo'}
                </MenuButton>
                <MenuList bgColor={'primary'} border={'1px solid white'} minWidth={'60px'} zIndex={'300'}>
                    <MenuItem fontFamily="poppins" bgColor={'primary'}>
                        <ModalNewStrategie
                            name='Nova Estratégia'
                            icon={<IoDuplicateSharp size={'16px'} />}
                            action={handleSave}
                            setNameStrategie={setNameStrategies}
                            isCreate={true}
                            resetTable={resetTable} />
                    </MenuItem>
                    <MenuItem fontFamily="poppins" bgColor={'primary'} onClick={() => handleUpdate()}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <Text marginLeft={'5px'}>Salvar</Text>
                        </Flex>
                    </MenuItem>
                    <MenuItem fontFamily="poppins" bgColor={'primary'}>
                        <ModalNewStrategie
                            name='Renomear'
                            icon={<SlPencil size={'16px'} />}
                            action={handleUpdate}
                            setNameStrategie={setNameStrategies}
                            isCreate={false} />
                    </MenuItem>
                    <MenuItem fontFamily="poppins" bgColor={'primary'}>
                        <ModalDeleteStrategie
                            name='Excluir'
                            icon={<FiDelete size={'16px'} />}
                            deleteStrategie={handleRemove} />
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}

export default MenuActions;

