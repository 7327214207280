import { 
    Box, 
    Button, 
    Flex, 
    Input, 
    MenuButton, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Text, 
    useDisclosure 
} from "@chakra-ui/react";
import backgroundImageLogin from '../../assets/dashboard-login.png'
// import vectorRegister from '../../assets/vector-register.png'
import { useState } from "react";
import authService from "../../services/authService";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";


const Register = () => {
    const [form, setForm] = useState({
        username: '',
        email: '',
        password: ''
    })
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleClickInRegister() {
        try {
            let discordId = localStorage.getItem('discId')
            setLoading(true)
            let userCreated = await authService.registerUser(discordId ? {...form, 'discordId':discordId }: form)
            if (userCreated.statusCode === 201 || userCreated.statusCode === 200) {
                // localStorage.setItem('token', userCreated.token)
                // localStorage.setItem('research-user-data', JSON.stringify(userCreated.user))
                // setAuthToken(userCreated.token)
                localStorage.removeItem('discId')
                navigate('/login')
                toast.success('Verifique seu email para confirmar a conta. ')
                setLoading(false)
            } else {
                setLoading(false)
                toast.error(userCreated.response || userCreated.error)
            }
        } catch (error) {
            toast.error('Algo deu errado no processamento dos dados. ')
        }

    }


    return (
        <Box maxW={'1920px'} h={'100vh'} bg={'#000000'}>
            <Box h={'90%'} backgroundImage={`url(${backgroundImageLogin})`} backgroundPosition={'100% 35%'} margin={'auto'}>
                <Flex
                    // bg={"red"}
                    height={'100%'}
                    flexDirection={'column'}>
                    <Box maxW={'95rem'} h={'148px'} alignItems={'center'} margin={'auto'}>
                        <Text
                            color={'white'}
                            fontSize={'42px'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                            marginTop={'35px'}
                        >
                            BELO TRADE RESEARCH
                        </Text>
                        <Box maxW={'95rem'} h={'59px'} alignItems={'center'}>
                            <Text
                                color={'white'}
                                fontSize={'16px'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                marginTop={'15px'}
                            >
                                TODAS  AS  INFORMAÇÕES  PARA  UM  <i>CRYPTO TRADER</i> &nbsp; EM  UM  SO  LUGAR
                            </Text>
                        </Box>
                    </Box>

                    <Flex
                        w={'50%'}
                        alignItems={'center'}
                        flexDirection={'column'}
                        margin={'auto'}>
                        <Text
                            fontFamily={'Poppins'}
                            marginBottom={'30px'}
                            align={'center'}
                            fontSize={'16px'}
                            color={'white'}>
                            Registre-se gratuitamente
                        </Text>
                        <Input
                            type={'text'}
                            placeholder={'Seu nome completo'}
                            w={'100%'}
                            color={'white'}
                            borderColor={'white'}
                            onChange={(e) => setForm({ ...form, 'username': e.target.value })}
                        />
                        <Input
                            mt={'15px'}
                            type={'email'}
                            placeholder={'E-mail ou usário Discord'}
                            color={'white'}
                            w={'100%'}
                            borderColor={'white'}
                            onChange={(e) => setForm({ ...form, 'email': e.target.value })}
                        />
                        <Input
                            mt={'15px'}
                            type={'password'}
                            placeholder={'Senha'}
                            color={'white'}
                            w={'100%'}
                            borderColor={'white'}
                            onChange={(e) => setForm({ ...form, 'password': e.target.value })}
                        />
                        <Link to='/login' style={{width: '100%', align:'start'}}>
                            <Text
                                color={'white'}
                                fontSize={'12px'}
                                mt={'15px'}
                                cursor={'pointer'}
                                _hover={{ color: 'activeGreen' }}
                                fontFamily="poppins"
                                >
                                Já sou cadastrado
                            </Text>
                        </Link>

                        <Box
                            w={'100%'}
                            align={'center'}>
                            <Button
                                // mr={'10px'}
                                mt={'25px'}
                                w={'90px'}
                                variant='outline'
                                color={'white'}
                                _hover={{ color: 'activeGreen', boxShadow: '1px 1px gray' }}
                                onClick={handleClickInRegister}
                            >
                                {loading ? <Spinner /> : 'REGISTRAR'}
                            </Button>
                        </Box>
                    </Flex>
                </Flex>
                {/* <Image src={vectorRegister} w={'650px'} h={'600px'} /> */}
            </Box>
        </Box >
    );
}

export default Register;