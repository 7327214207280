import { Flex, Grid, GridItem, Table } from '@chakra-ui/react'
import { TableCaption, TableContainer, Tbody, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect } from 'react';
import { useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import styles from './Table.module.css'
import Dots from "react-activity/dist/Dots";
import "react-activity/dist/Dots.css";
import GenericFilter from '../../../components/Filter/GenericFilter';
import Td from '../../../components/TableComponents/Td';

const TablePerson = ({ columns, dataTable, handleUpdateSymbols, updateTable, order, handleFilterTable, filtersApplieds }) => {

    let [appliedFilter, setAppliedFilter] = useState({})
    let [dataFilter, setDataFilter] = useState([])
    let [clickedInFilter, setClickedInFilter] = useState(true)


    useEffect(() => {
        // if (dataTable.length)
        setDataFilter(dataTable)
    }, [dataTable])


    function changeColor(value) {
        if (parseFloat(value) > 0)
            return '#22C55E'
        else if (parseFloat(value) < 0)
            return '#EC4889'
        else
            return '#FFFFFF'
    }

    function DataOrderingByColumn(column) {
        if (column.toUpperCase() === 'SÍMBOLOS') {
            if (order) {
                dataTable.symbols = dataTable?.symbols.sort()
                handleUpdateSymbols(dataTable, column)
            } else {
                dataTable.symbols = dataTable.symbols.reverse()
                handleUpdateSymbols(dataTable, column)
            }
        } else if (column === '15m Δ%') {
            if (order) {
                dataTable.symbols = dataTable.symbols.sort((a, b) => {
                    if (parseFloat(dataTable['15m'][a]) > parseFloat(dataTable['15m'][b]))
                        return 1
                    if (parseFloat(dataTable['15m'][a]) < parseFloat(dataTable['15m'][b]))
                        return -1
                })
                handleUpdateSymbols(dataTable, column)
            } else {
                dataTable.symbols = dataTable.symbols.reverse()
                handleUpdateSymbols(dataTable, column)
            }
        } else if (column === '1h Δ%') {
            if (order) {
                dataTable.symbols = dataTable.symbols.sort((a, b) => {
                    if (parseFloat(dataTable['1h'][a]) > parseFloat(dataTable['1h'][b]))
                        return 1
                    if (parseFloat(dataTable['1h'][a]) < parseFloat(dataTable['1h'][b]))
                        return -1
                })
                handleUpdateSymbols(dataTable, column)
            } else {
                dataTable.symbols = dataTable.symbols.reverse()
                handleUpdateSymbols(dataTable, column)
            }
        } else if (column === '4h Δ%') {
            if (order) {
                dataTable.symbols = dataTable.symbols.sort((a, b) => {
                    if (parseFloat(dataTable['4h'][a]) > parseFloat(dataTable['4h'][b]))
                        return 1
                    if (parseFloat(dataTable['4h'][a]) < parseFloat(dataTable['4h'][b]))
                        return -1
                })
                handleUpdateSymbols(dataTable, column)
            } else {
                dataTable.symbols = dataTable.symbols.reverse()
                handleUpdateSymbols(dataTable, column)
            }
        } else {
            console.log('Invalid column. ')
        }
    }

    function filterByColumn(data, column, filter, position, value) {
        let newDatWithFilter = data['symbols'].filter(symbol => {
            if (filter === '>')
                return parseFloat(data[column][symbol]) > parseFloat(value)
            if (filter === '>=')
                return parseFloat(data[column][symbol]) >= parseFloat(value)
            if (filter === '<')
                return parseFloat(data[column][symbol]) < parseFloat(value)
            if (filter === '<=')
                return parseFloat(data[column][symbol]) <= parseFloat(value)
            if (filter === '=')
                return parseFloat(data[column][symbol]) === parseFloat(value)
        })
        data.symbols = newDatWithFilter.sort()
        handleFilterTable(data, column)
    }

    function ApllyFilter(filter, column, value) {

        if (column === 'Símbolos') {
            let newDatWithFilter = dataFilter['symbols'].filter(symbol => symbol === value)
            dataFilter.symbols = newDatWithFilter.sort()
            handleFilterTable(dataFilter, column)
        }
        else if (column.startsWith('15m')) {
            filterByColumn(dataFilter, '15m', filter, 1, value)
        }
        else if (column.startsWith('1h')) {
            filterByColumn(dataFilter, '1h', filter, 2, value)
        }
        else if (column.startsWith('4h')) {
            filterByColumn(dataFilter, '4h', filter, 3, value)
        }
    }

    function ApllyFilterAgain(filter, column, value) {

        if (column === 'Símbolos') {
            let newDatWithFilter = dataFilter['symbols'].filter(symbol => symbol === value)
            dataFilter.symbols = newDatWithFilter.sort()
            handleFilterTable(dataFilter, column)
        }
        else if (column.startsWith('15m')) {
            filterByColumn(dataFilter, '15m', filter, 1, value)
        }
        else if (column.startsWith('1h')) {
            filterByColumn(dataFilter, '1h', filter, 2, value)
        }
        else if (column.startsWith('4h')) {
            filterByColumn(dataFilter, '4h', filter, 3, value)
        }
    }

    function handleFilter(filter, column) {
        if (Object.keys(appliedFilter).indexOf(column) !== -1) {
            console.log('limpando o filtro antigo agr dale!')
            handleClearFilter(filter, column)
        }
        let newFilter = { ...appliedFilter, [column]: filter }
        let columnsFilters = Object.keys(newFilter)
        setAppliedFilter(newFilter)
        console.log('columnsFilters')
        console.log(columnsFilters)
        console.log('columns')
        console.log(columns)
        Object.keys(columns).map(th => {
            if (columnsFilters.indexOf(th) !== -1) {
                console.log('newFilter[th][filter], th, newFilter[th][value]')
                console.log(newFilter[th]['filter'], th, newFilter[th]['value'])
                ApllyFilter(newFilter[th]['filter'], th, newFilter[th]['value'])
            }
        })
    }

    function handleClearFilter(filter, column) {

        let newFilter = { ...appliedFilter }
        delete newFilter[column]
        setAppliedFilter(newFilter)
        if (!Object.keys(newFilter).length) {
            dataTable['symbols'] = JSON.parse(localStorage.getItem('symbols'))
            handleUpdateSymbols(dataTable, column)
            return
        }

        dataTable['symbols'] = JSON.parse(localStorage.getItem('symbols'))
        let columnsFilters = Object.keys(newFilter)

        dataTable.map((f, i) => {
            if (i > 0 && columnsFilters.indexOf(Object.keys(f) + ' Δ%') !== -1) {
                ApllyFilterAgain(
                    newFilter[Object.keys(f) + ' Δ%']['filter'],
                    Object.keys(f),
                    newFilter[Object.keys(f) + ' Δ%']['value'])
            }
        })
    }

    return (<>
        <TableContainer borderRadius={30} overflowX={'hidden'}>
            <Table variant={'unstyled'} textColor={'text'}>
                <Thead bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            // onClick={() => { DataOrderingByColumn(th) }}
                            <Th key={th}
                                textColor={'text'}
                                _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                                cursor='pointer'
                                p={0}
                                w={150}
                                h={50}>
                                <Grid templateColumns='repeat(5, 1fr)' gap={0.1} justifyItems={'center'}>
                                    <GridItem w='100%' h='10' onClick={() => { DataOrderingByColumn(th) }} />
                                    <GridItem w='100%' h='10' display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={() => { DataOrderingByColumn(th) }}>
                                        <span className={styles.valueTable}>{th}</span>
                                    </GridItem>
                                    <GridItem w='100%'
                                        h='10' display={'flex'}
                                        alignItems={'center'} justifyContent={'center'}
                                        padding={'0'}
                                        onClick={() => { DataOrderingByColumn(th) }}>
                                        {!columns[th] ?
                                            <div className={styles.changeColor} onClick={() => { DataOrderingByColumn(th) }}>
                                                <FiArrowDown
                                                    fontSize='18px'
                                                    cursor='pointer'
                                                    transition='0.2s'
                                                    margin=' 0px 2px'
                                                    z-index='200'
                                                />
                                            </div>
                                            : <div className={styles.changeColor} onClick={() => { DataOrderingByColumn(th) }}>
                                                <FiArrowUp
                                                    fontSize='18px'
                                                    cursor='pointer'
                                                    transition='0.2s'
                                                    margin=' 0px 2px'
                                                    z-index='200'
                                                    onClick={() => { DataOrderingByColumn(th) }}
                                                />
                                            </div>}
                                    </GridItem>
                                    <GridItem w='18px' h='5' display={'flex'} alignItems={'center'} justifyContent={'center'} marginY={'10px'}>
                                        <div className={styles.changeColor}>
                                            <GenericFilter
                                                saveFilter={handleFilter}
                                                column={th}
                                                clearFilter={handleClearFilter}
                                                clickedState={clickedInFilter}
                                                handleClickInFilter={setClickedInFilter} />
                                        </div>
                                    </GridItem>
                                    <GridItem w='100%' h='10' onClick={() => { DataOrderingByColumn(th) }} />
                                </Grid>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody overflowX={'auto'} overflowY={'auto'} maxHeight={'450px'} display={'block'} >
                    {dataTable ?
                        dataTable?.symbols.map(((row, index) => <Tr key={index}>
                            <Td>
                                <Flex>
                                    <img src={`images/${row.toUpperCase()}.png`} width={18} height={18} />
                                    &nbsp; &nbsp;
                                    {row}
                                </Flex>
                            </Td>
                            <Td color={changeColor(dataTable['15m'][row])}>
                                {parseFloat(dataTable['15m'][row]) > 0 ? '+' : ''}
                                {dataTable['15m'][row] &&
                                    dataTable['15m'][row] !== "0" ? (dataTable['15m'][row]).toFixed(2) : "0.00"}
                                %
                            </Td>
                            <Td color={changeColor(dataTable['1h'][row])}>
                                {parseFloat(dataTable['1h'][row]) > 0 ? '+' : ''}
                                {dataTable['1h'][row]
                                    && dataTable['1h'][row] !== "0" ? (dataTable['1h'][row]).toFixed(2) : "0.00"}
                                %
                            </Td>

                            <Td color={changeColor(dataTable['4h'][row])}>
                                {parseFloat(dataTable['4h'][row]) > 0 ? '+' : ''}
                                {dataTable['4h'][row]
                                    && dataTable['4h'][row] !== "0" ? (dataTable['4h'][row]).toFixed(2) : "0.00"}
                                %
                            </Td>
                            {/* 
                            <Td color={changeColor((dataTable[4]['24h'][row]).toFixed(2))}>
                                {parseFloat(dataTable[4]['24h'][row]) > 0 ? '+' : ''}
                                {(dataTable[4]['24h'][row]).toFixed(2)}
                                %
                            </Td> */}

                        </Tr>)) : (
                            <Tr key={Date.now()}>
                                <Td>
                                    <Dots size={50} />
                                </Td>
                                <Td>
                                    <Dots size={50} />
                                </Td>
                                <Td>
                                    <Dots size={50} />
                                </Td>
                                <Td>
                                    <Dots size={50} />
                                </Td>
                                {/* <Td>
                                    <Dots size={50} />
                                </Td> */}
                            </Tr>)}
                </Tbody>
                <Tfoot bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            <Th key={th}></Th>
                        ))}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer >
    </>

    );
}

export default TablePerson;