import { api, requestConfig } from "../utils/config";

// Delete a user
const deleteUser = async (id) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("DELETE", null, token);

  try {
    const res = await fetch(`${api}/user/${id}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Update user type
const updateUserType = async (id) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("PUT", null, token);

  try {
    const res = await fetch(`${api}/user/change-type/${id}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};
// TODO: Retirar todos os .then ou os await
// Update user type
const updateUser = async (id, data) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("PUT", data, token);
  console.log(config)
  try {
    const res = await fetch(`${api}/user/${id}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Find users
const getUsers = async () => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(`${api}/user/list`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

const getPermissions = async () => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = await fetch(`${api}/user/permissions`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));
    return res;
  } catch (error) {
    console.log(error);
  }
};

const userService = {
  deleteUser,
  getUsers,
  updateUserType,
  updateUser,
  getPermissions
};

export default userService;
