import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import Timer from "../../components/Timer/Timer";
import styles from './OpenInterest.module.css'
import TablePerson from "./Table/Table";
import { useState } from "react";
import axios from 'axios'
import { useEffect } from "react";
import { getMaskedValue } from "../../utils/maskValue";
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import TableData from "../../services/TableDataService";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";



const UNAVAILABLE_SYMBOLS = ['btc_221230', 'btc_230331', 'eth_221230', 'eth_230331', 'ray', 'tlm', 'bts', 'sc', 'ftt', 'srm', 'cvc', 'fet', 'fxs', 'hook']

const OpenInterest = ({ updateNow }) => {
    const columnsFix = {
        'Símbolos': false,
        'OI $': false,
        '15m Δ%': false,
        '1h Δ%': false,
        '4h Δ%': false,
        '24h Δ%': false
    }

    const [data, setData] = useState(JSON.parse(localStorage.getItem('OIPage')))
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    const [columns, setColumns] = useState(columnsFix)
    const [order, setOrder] = useState(true)
    const [symbols, setSymbols] = useState([])
    const [updatedPage, setUpdatedPage] = useState(false)
    const [filterApplied, setFilterApplied] = useState([])
    const [updateTable, setUpdateTable] = useState(false)
    // const [timer, setTimer] = useState(false)
    const [openInterestGlobal, setOpenInterestGlobal] = useState(0)

    function updateSymbols(newData, column) {
        let newColumnsConfig = columnsFix
        newColumnsConfig[column] = columns[column] ? false : true
        setOrder(!order)
        setColumns(newColumnsConfig)
        setData(newData)
    }

    function handleFilterTable(newData, column) {
        if (filterApplied.length && filterApplied.indexOf(column) !== -1) {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setData(newData)
        } else {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setFilterApplied([...filterApplied, column])
            setData(newData)
        }
    }


    function formatData(data, symbol) {
        console.log('data - formatData')
        console.log(data)
        setOpenInterestGlobal(parseFloat(data['OI'][0]['LsrOrOIGlobal']))
        setLastUpdatedAt(new Date(data['OI'][0].createdAt))
        // symbol = symbol.map(e => e.symbol)
        let symbols = []
        let newData = { 'oi': {}, '15m': {}, '1h': {}, '4h': {}, '24h': {}, symbols: symbols }
        data['OI'].map(d => {
            console.log(d.symbol)
            symbols.push(d.symbol)
            newData['oi'] = { ...newData['oi'], [d.symbol]: parseFloat(d['LsrOrOI']) }
            newData['15m'] = { ...newData['15m'], [d.symbol]: parseFloat(d['15m']) }
            newData['1h'] = { ...newData['1h'], [d.symbol]: parseFloat(d['1h']) }
            newData['4h'] = { ...newData['4h'], [d.symbol]: parseFloat(d['4h']) }
            newData['24h'] = { ...newData['24h'], [d.symbol]: parseFloat(d['24h']) }
        })

        console.log(newData)
        return newData
    }

    function OrderByOI(dataTable) {
        dataTable[0].symbols = dataTable[0].symbols.sort((a, b) => {
            if (parseFloat(dataTable[1]['io'][a]) > parseFloat(dataTable[1]['io'][b]))
                return 1
            if (parseFloat(dataTable[1]['io'][a]) < parseFloat(dataTable[1]['io'][b]))
                return -1
        }).reverse()
        return dataTable
    }

    useEffect(() => {
        async function fetchData() {
            let symbols = await TableData.getSymbols()
            let response = await TableData.getTableData('OI')
            let dataFormated = formatData(response['data'], symbols['data'])
            localStorage.setItem('OIPage', JSON.stringify(dataFormated))
            setData(dataFormated)
            console.log(response['data'])
            setUpdateTable(true)
        }
        fetchData()
    }, [updatedPage])



    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Open Interest
                    </Text>

                    <Text
                        fontSize='14px'
                        color='white'
                        margin='10px'>
                        OI Global:  $<b>{getMaskedValue(openInterestGlobal)}</b>
                    </Text>
                    <NotificationSongGlobal />
                    <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                    {/* <GenericTimer lastUpdatedAt={lastUpdatedAt} /> */}
                    {/* <div>
                        <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                            <Timer
                                loadData={data}
                                handleUpdateTable={setTimer}
                                statusTimer={timer}
                                actionUpdateTable={setUpdateTable} />
                        </Flex>
                    </div> */}
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} symbols={symbols}
                                dataTable={data} handleUpdateSymbols={updateSymbols}
                                updateTable={updateTable} order={order} handleFilterTable={handleFilterTable}
                                filtersApplieds={filterApplied} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default OpenInterest;