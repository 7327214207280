import { 
    Button, 
    Flex, 
    HStack, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    PinInput, 
    PinInputField, 
    Text, 
    useDisclosure 
} from "@chakra-ui/react";
import { useState } from "react";
import authService from "../../../services/authService";
import { toast } from "react-toastify";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { useEffect } from "react";

const CheckModal = ({ open, close }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [emailToRecoverPassword, setEmailToRecoverPassword] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    // const [pinCode, setPinCode] = useState('');
    
    useEffect(() => {
        console.log('estou aq ')
        console.log(open)
        if(open) onOpen(true)
    },[open])

    function handleClose(){
        onClose(true)
        close(false)
    }

    return ( 
    <Modal isOpen={isOpen} onClose={() => handleClose()}>
        <ModalOverlay />
        <ModalContent width={'435px'} bg={'primary'} border={'1px solid white'} color={'white'}>
            <ModalHeader color={'white'}>Verifique sua conta</ModalHeader>
            <ModalCloseButton color={'white'}/>
            <ModalBody>
                <Text>
                    Enviamos um link por email para verificar sua conta, <b>lembre-se de verificar sua 
                    caixa de span</b></Text>
                {/* <HStack mt={'15px'}>
                    <PinInput otp size='lg' placeholder="0" type='alphanumeric' onChange={handlePinCodeChange}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack> */}
            </ModalBody>
            <ModalFooter>
                <Flex>
                    {/* <Button colorScheme='blue' mr={3} onClick={() => clearConfig()}> */}
                    <Button color={'black'} onClick={() => handleClose()}>
                        OK
                    </Button>
                </Flex>
            </ModalFooter>
        </ModalContent>
    </Modal > );
}
 
export default CheckModal;