import { extendTheme } from '@chakra-ui/react'
import { menuTheme } from '../components/Menu/Menu'


export const theme = extendTheme({
    colors: {
        primary: 'rgba(0, 0, 0, 0.95)',
        secondary: 'rgba(40, 40, 40, 0.8)',
        header: 'rgba(40, 40, 40, 0.3)',
        activeGreen: '#22c55e',
        text: '#FFFFFF',
        textTest: 'red'
    },
    fonts: {
        // heading: 'Poppins',
        heading: 'Roboto',
        body: 'Roboto',
        poppins: 'Poppins'
    },
    components: {
        Menu: menuTheme
    }
})