export const getMaskedValue = (value) => {
  if (!value) return '0.0'
  const valueAsNumber = Number(value);
  if (!valueAsNumber) return value ? `${value}` : '';

  const thousands = Math.floor(valueAsNumber / 1000);
  const milions = Math.floor(valueAsNumber / 1000000);
  const bilions = (valueAsNumber / 1000000000) > 0 && (valueAsNumber / 1000000000) < 1 ? 0 : (valueAsNumber / 1000000000).toFixed(2)
  const trillions = Math.floor(valueAsNumber / 1000000000000);

  if (trillions) return `${trillions}T`;
  if (bilions) return `${parseFloat(bilions)}B`;
  if (milions) return `${milions}M`;
  if (thousands) return `${thousands}K`;
  return valueAsNumber ? `${valueAsNumber}` : '';
};
