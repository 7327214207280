export const formatTime = (time) => {
    return time < 10 ? `0${time}` : time.toString();
};

export const formatHour = (date) => {
    const hours = formatTime(date.getHours());
    const minutes = formatTime(date.getMinutes());
    const seconds = formatTime(date.getSeconds());

    return `${hours}:${minutes}:${seconds}`;
};
