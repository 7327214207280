import { Flex, Table, Text } from '@chakra-ui/react'
import { TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import dayjs from 'dayjs'
import styles from './Table.module.css'

const TablePerson = ({ columns, dataTable }) => {

    function changeColor(value) {
        if (value && parseFloat(value) > 0)
            return '#22C55E'
        else if (value && parseFloat(value) < 0)
            return '#EC4889'
        else
            return '#FFFFFF'
    }
    

    return (<>
        <TableContainer borderRadius={30} overflowX={'hidden'}>
            <Table variant={'unstyled'} textColor={'text'}>
                <Thead bg={'secondary'} display={'block'}>
                    <Tr>
                        {columns.map(th => (
                            // onClick={() => { DataOrderingByColumn(th) }}
                            <Th key={th}
                                textColor={'text'}
                                _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                                w={'150px'}
                                h={'50px'}>
                                <Flex justifyContent={'center'}>
                                    <span className={styles.valueTable}>{th}</span>
                                </Flex>

                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody 
                    overflowX={'auto'} 
                    overflowY={'auto'} 
                    maxHeight={'410px'} 
                    display={'block'} 
                    minWidth={'900px'}>
                    {dataTable.length ?
                        dataTable.map(strategie => (
                            <Tr>
                                <Td w={(1/6) * 900}>
                                <Flex>
                                    <img 
                                        src={`images/${strategie['symbol']}.png`} 
                                        width={18} 
                                        height={18} 
                                        alt={strategie['symbol']}/>
                                    &nbsp; &nbsp;
                                    {strategie['symbol']}
                                </Flex>
                                </Td>
                                <Td maxW={(1/6) * 900}>
                                    <Flex justifyContent={'start'}>
                                        <Text fontSize={'12px'}>{strategie['strategieName']}</Text>
                                    </Flex>
                                </Td>
                                <Td 
                                    color={changeColor(strategie['LSR:4h'])}
                                    w={(1/6) * 900}>
                                    {strategie['LSR:4h'] ? 
                                        parseFloat(strategie['LSR:4h']).toFixed(4) : '-' }
                                </Td>
                                <Td  
                                    color={changeColor(strategie['OI:4h'])} 
                                    w={(1/6) * 900}>
                                    {strategie['OI:4h'] ?
                                    parseFloat(strategie['OI:4h']).toFixed(4) : '-'}
                                </Td>
                                <Td 
                                    color={changeColor(strategie['FR'])}
                                    w={(1/6) * 900}>
                                    {strategie['FR'] ? strategie['FR'] : '-'}
                                </Td>
                                <Td 
                                    w={(1/6) * 900}>
                                    {dayjs(strategie['createdAt']).format("DD/MM HH:mm")}  
                                </Td>
                            </Tr> 
                        )) :
                        (<Tr>
                            <Td></Td><Td></Td><Td></Td>
                            <Td></Td><Td></Td><Td></Td>
                        </Tr>)
                    }
                </Tbody>
                <Tfoot bg={'secondary'} display={'block'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            <Th key={th}></Th>
                        ))}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer >
    </>

    );
}

export default TablePerson;