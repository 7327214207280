export function formatFiltersToSave(filters) {
    if (!filters) return {}
    let response = {}
    Object.entries(filters).map(f => {
        let column = f[0]
        let data = f[1]
        response[column] = `${data['filter']}/${data['value']}`
    })
    return response
}

export function formatFiltersToApply(filters) {
    if (!filters) return {}
    let response = {}
    Object.entries(filters).map(f => {
        let column = f[0]
        let data = f[1] ? f[1].split('/') : []
        if (data[1]) {
            response[column] = { filter: data[0], value: data[1] }
        } else {
            if (column === 'symbol' && filters['symbol'])
                response['Símbolo'] = { value: data[0] }
        }

    })
    return response
}
