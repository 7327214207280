import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import styles from './Radar.module.css'
import radarService from '../../services/radarService'
import TablePerson from "./components/Table/Table";
import { useState } from "react";
import { useEffect } from "react";

import { formatFiltersToApply, formatFiltersToSave } from '../../utils/filters';

import ModalColumns from "./components/ModalColumns/ModalColumns";
import MenuActions from "./components/MenuActions/MenuActions";
import ModalStrategies from "./components/ModalStrategies/ModalStrategies";
import { AiOutlineDownload } from "react-icons/ai";
import TableData from "../../services/TableDataService";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";
import { useContext } from "react";
import { FilterContext } from '../../hooks/Filter/Filter'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Radar = () => {
    const columnsFix = {
        'Símbolo': false
    }
    const navigate = useNavigate()
    const { filters, applyFilter, defineBeforeData,
        dataBeforeFilter, clearFilter, setFilterData } = useContext(FilterContext)


    const [data, setData] = useState(JSON.parse(
        localStorage.getItem('RadarPage') ?
            localStorage.getItem('RadarPage') : null))

    const [name, setName] = useState('')
    const [columns, setColumns] = useState(columnsFix)
    const [order, setOrder] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [symbols, setSymbols] = useState([])
    const [updatedPage, setUpdatedPage] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    /**
     * 
     * @param data - Nome da stratégia no formato de objeto -> {name: 'teste 1'}
     */
    async function saveStrategie(data) {
        if (!data.name) {
            delete data['Símbolo']
            Object.keys(data).map(col => {
                data[col] = true
            })
            data['name'] = name ? name : 'Novo modelo' + new Date().getTime()
            setUpdating(false)
            let createdStrategie = await radarService.createRadar(data)
            if (createdStrategie.statusCode > 400) {
                toast.error(createdStrategie.data.message || createdStrategie.message)
            } else {
                toast.success('Radar criado com sucesso. ')
            }
        } else {
            setUpdating(false)
            let createdStrategie = await radarService.createRadar(data)
            if (createdStrategie.statusCode > 400) {
                toast.error(createdStrategie.data.message || createdStrategie.message)
            } else {
                toast.success('Radar criado com sucesso. ')
            }
        }

    }

    async function deleteStrategieModal() {
        let columnsToDelete = columns
        columnsToDelete['Símbolo'] = false
        let data = await radarService.deleteRadar(name)
        if (data.statusCode > 400) {
            toast.error(data.data.message || data.message)
        } else {
            Object.keys(columns).map(col => {
                if (col !== 'Símbolo') {
                    delete columnsToDelete[col]
                    deleteColumn(columnsToDelete, col)
                }
            })
            toast.success('Radar deletado com sucesso. ')
            setName('Novo modelo')
        }
    }
    /**
     * @param rename - Usamos apenas no caso de renomear a estrategia, mas é uma string com o nome da nova tabela
     */
    async function updateStrategie(rename) {
        if (rename) {
            let data = await radarService.updateRadar({ name: rename }, name)
            if (data.statusCode > 400) {
                setName(name)
                toast.error(data.data.message || data.message)
            } else {
                localStorage.setItem('currentRadar', rename)
                setName(rename)
                toast.success('Radar atualizado com sucesso. ')
            }
        } else {
            let newName = name ? name : 'Novo modelo' + new Date().getTime()
            let newData = { ...columns }
            let newDataClone = { ...columns }
            // delete newData['Símbolo']
            Object.keys(newData).map(col => {
                newData[col] = true
                newDataClone[col] = true
            })
            delete newDataClone['filters']
            setColumns(newDataClone)
            newData['filters'] = formatFiltersToSave(filters)
            let data = await radarService.updateRadar(newData, newName)
            if (data.statusCode > 400) {
                toast.error(data.data.message || data.message)
            } else {
                toast.success('Radar atualizado com sucesso. ')
            }
        }

    }

    function updateSymbols(newData, column, columns) {
        columns[column] = columns[column] ? false : true
        if (newData.filters)
            delete newData['filters']
        setOrder(!order)
        setColumns(columns)
        setData(newData)
        setFilterData(newData)
    }

    /**
     * 
     * @param newColumns - {Símbolo: false, OI: false}
     * @param currentColumn - 'LSR' foi a coluna q escolhemos para deletar
     */
    function deleteColumn(newColumns, currentColumn) {
        let dataTableObject = { ...data }
        delete dataTableObject[currentColumn]
        if (currentColumn.startsWith('filters')) {
            delete currentColumn['filters']
        }
        console.log('newColumns')
        console.log(newColumns)
        console.log(dataTableObject)
        setColumns(newColumns)
        setData(dataTableObject)
    }

    /**
     * 
     * @param dataColumn - {OI: 'OI', FR: 'FR', LSR: '15m',OI: '15m'}
     * @param method - get ou post 
     */
    async function handleSave(dataColumn, method = 'post') {

        let newData = {}
        if (method === 'get') {
            let CloneData = data
            delete CloneData['symbols']
            newData = Object.assign({}, CloneData)
        }
        await Promise.all(dataColumn.map(async (col) => {
            if (col.includes(':')) {
                let name = col.split(':')[0]
                let timing = col.split(':')[1]
                let dataCol = await TableData.getTableData(name, timing)
                if (dataCol.statusCode > 400) {
                    toast.error(dataCol.data.message || dataCol.message)
                } else {
                    dataCol = dataCol['data']
                    let data = {}
                    dataCol.map(e => {
                        data[Object.keys(e)[0]] = Object.values(e)[0]
                    })
                    if (name !== timing.toUpperCase())
                        newData[col] = data
                    else
                        newData[name] = data
                }

            } else {
                let dataCol = await TableData.getTableData(col, col)['data']
                if (dataCol.statusCode > 400) {
                    toast.error(dataCol.data.message || dataCol.message)
                } else {
                    dataCol = dataCol['data']
                    let data = {}
                    dataCol.map(e => {
                        data[Object.keys(e)[0]] = Object.values(e)[0]
                    })
                    newData[col] = data
                }
            }
        }))
        let newColumns = { ...columnsFix }
        Object.keys(newData).map(col => {
            newColumns[col] = false
        })
        setColumns(newColumns)
        setData({ ...newData, symbols: symbols })
        defineBeforeData({ ...newData, symbols: dataBeforeFilter.symbols })
    }

    /**
     * 
     * @param name - strategie name
     */
    async function setNewStrategie(nameStrategie, defaultStrategie = false) {
        let symbols = await TableData.getSymbols()
        let currentStrategie = await radarService.getRadar(nameStrategie)
        if (currentStrategie.statusCode > 400) {
            toast.error(currentStrategie.data.message)
        } else {
            setName((currentStrategie && currentStrategie['data']['name']) ? currentStrategie['data']['name'] : 'Novo Modelo')
            localStorage.setItem('currentRadar', currentStrategie['data']['name'])
            let newData = {}

            if (defaultStrategie) {
                setLastUpdatedAt(
                    currentStrategie['data']['date'] ?
                        new Date(currentStrategie['data']['date']) :
                        new Date()
                )
            }
            Object.entries(currentStrategie['data']['columns']).map(col => {
                let data = {}
                col[1].map(e => {
                    data[Object.keys(e)[0]] = Object.values(e)[0]
                })
                newData[col[0]] = data
            })
            let newColumns = { ...columnsFix }

            Object.keys(newData).map(col => {
                newColumns[col] = false
            })

            localStorage.setItem('RadarPage', JSON.stringify({ ...newData, symbols: symbols['data'].map(e => e.symbol) }))

            setColumns(newColumns)
            defineBeforeData({ ...newData, symbols: symbols['data'].map(e => e.symbol) })
            applyFilter(
                { ...newData, symbols: symbols['data'].map(e => e.symbol) },
                currentStrategie.data['filters'] ?
                    formatFiltersToApply(currentStrategie.data['filters']) : {}
            )

            setData({ ...newData, symbols: symbols['data'].map(e => e.symbol) })
        }

    }

    async function resetTable() {
        setData({ symbols: data['symbols'] })
        setColumns(columnsFix)
    }

    useEffect(() => {
        async function fetchData() {
            try {
                // setUpdateTable(!updateTable)
                let symbols = await TableData.getSymbols()
                let strategies = await radarService.getRadar()

                if (symbols.statusCode >= 400 || strategies.statusCode >= 400) {
                    toast.error(strategies.data.message)
                    navigate('/')
                } else {
                    if (strategies['data']['strategies'].length) {
                        let currentStrategie = await radarService.getRadar(strategies['data']['strategies'][0].name)
                        setName(currentStrategie['data']['name'])

                        let newData = {}
                        Object.entries(currentStrategie['data']['columns']).map(col => {
                            let data = {}
                            col[1].map(e => {
                                data[Object.keys(e)[0]] = Object.values(e)[0]
                            })
                            newData[col[0]] = data
                        })
                        let newColumns = { ...columnsFix }

                        Object.keys(newData).map((col, index) => {
                            newColumns[col] = false
                        })
                        setLastUpdatedAt(
                            currentStrategie['data']['date'] ?
                                new Date(currentStrategie['data']['date']) :
                                new Date()
                        )

                        localStorage.setItem('symbols', JSON.stringify(symbols['data'].map(e => e.symbol)))
                        setColumns(newColumns)
                        localStorage.setItem('RadarPage', JSON.stringify({ ...newData, symbols: symbols['data'].map(e => e.symbol) }))
                        setData({ ...newData, symbols: symbols['data'].map(e => e.symbol) })

                        defineBeforeData({ ...newData, symbols: symbols['data'].map(e => e.symbol) })

                        applyFilter(
                            { ...newData, symbols: symbols['data'].map(e => e.symbol) },
                            currentStrategie['data']['filters'] ? formatFiltersToApply(currentStrategie['data']['filters']) : {})
                        setSymbols(symbols['data'].map(e => e.symbol))
                        // setUpdateTable(true)
                    }
                }

            } catch (error) {
                console.log('error')
                console.log(error)
                toast.error('Algo deu errado, espere um pouco e tente novamente em breve. ')
            }

        }
        let currentStrategie = localStorage.getItem('currentRadar')
        if (currentStrategie) {
            setNewStrategie(currentStrategie, true)
        } else
            fetchData()
    }, [updatedPage])


    function clearFilterInTable() {
        clearFilter()
        setFilterData(dataBeforeFilter)
    }

    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Radar
                    </Text>
                    <div>
                        <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                            <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                        </Flex>
                    </div>
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <div>
                                <Flex justifyContent={'space-between'} >
                                    <div>
                                        <Button
                                            marginTop={'5px'}
                                            marginBottom={'5px'}
                                            marginRight={'10px'}
                                            onClick={() => clearFilterInTable()}
                                            bg='header'
                                            color='white'
                                            _hover={{ bg: 'secondary', color: 'activeGreen' }}>Limpar filtros</Button>
                                        <ModalColumns handleSave={handleSave} />
                                    </div>
                                    <div>
                                        <ModalStrategies icon={<AiOutlineDownload />} setStrategie={setNewStrategie} />
                                        <MenuActions
                                            handleSave={saveStrategie}
                                            handleUpdate={updateStrategie}
                                            handleName={setName}
                                            handleRemove={deleteStrategieModal}
                                            name={name}
                                            resetTable={resetTable}
                                            updating={updating}
                                        />
                                    </div>
                                </Flex>
                            </div>
                            <TablePerson
                                columns={columns}
                                dataTable={data}
                                handleUpdateSymbols={updateSymbols}
                                order={order}
                                deleteColumn={deleteColumn}
                            />
                        </div>
                    </Flex>
                </Flex>
            </div>
        </div >
    );
}

export default Radar;