import { createContext, useState } from "react"
import { toast } from "react-toastify";
import UserService from '../../services/userService'

export const PermissionContext = createContext()


function PermissionProvider({ children }) {
    // const [permission, setPermission] = useState(null)
    
    const LoadPermission = async (discordId) => {
        try {
        console.log('discordId')
        console.log(discordId)
        if (discordId) {
            console.log('aqui')
            let hasUserDiscord = null
            try {
                hasUserDiscord = await UserService.getPermissions()
            } catch (error) {
                console.log('error')
                toast.warning(error.response.data.message || error.response.data.error )
            }
            if(hasUserDiscord && hasUserDiscord.statusCode < 400){
                console.log(hasUserDiscord)
                let permissions = hasUserDiscord ? Object.entries(hasUserDiscord.data.permissions).map(p => ({[p[0]]: p[1]})) : {}
                let user = JSON.parse(localStorage.getItem('research-user-data'))
                user.discordId = discordId
                localStorage.setItem('research-user-data', JSON.stringify(user))
                localStorage.setItem('permissions', JSON.stringify(permissions))
            }
        }
        } catch (error) {
            console.log(error)
            // TODO: REMOVER ESSA MENSAGEM POIS SO IRÁ DA ERRO SE O USUÁRIO N TIVER NO DISCORD DA BELO TRADE, POREM JÁ TEMOS UMA AÇÃO PARA ISSO
            toast.error('Ocorreu um erro ao carregar as suas permissões. ')
            localStorage.removeItem('discId')
            console.log(error);
            
        }
    };

    return (
        <PermissionContext.Provider value={{ LoadPermission }}>
            {children}
        </PermissionContext.Provider>
    )
}

export default PermissionProvider