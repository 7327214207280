import { useEffect, useState } from "react";
import alertsStatus from "../../services/alertStatus";
import openSocket from "../../services/socket-io";
import NotificationSong from "../NotificationSong/NotificationSong";

const NotificationSongGlobal = () => {

    const [alertTradingview, setAlertAlertTradingview] = useState(false)
    const [alertTrdr, setAlertTrdr] = useState(false)

    // const [isFirstTime, setFirstTime] = useState(true)
    // const [isFirstTimeTrdr, setIsFirstTimeTrdr] = useState(true)


    useEffect(() => {
        const socket = openSocket();

        socket.emit('client-conected', () => {
            console.log('Client connected. ')
        })

        socket.on('alertTradingview', (alrt) => {
            alertsStatus.getAlertStatus('tradingView').then(response => {
                if (response.data) {
                    console.log(response.data)
                    setAlertAlertTradingview(prev => !prev)
                }
            }).catch(err => console.log(err))
            // let tradingViewAlarmStatus = localStorage.getItem("alarmTriggerStatustradingView")
            // if (tradingViewAlarmStatus === 'true')
            //     setAlertAlertTradingview(prev => !prev)
        })

        socket.on('alertTrdr', (alrt) => {
            alertsStatus.getAlertStatus('trdr').then(response => {
                if (response.data) {
                    console.log(response.data)
                    setAlertTrdr(prev => !prev)
                }
            }).catch(err => console.log(err))
            // let trdrAlarmStatus = localStorage.getItem("alarmTriggerStatustrdr")
            // if (trdrAlarmStatus === 'true')
            //     setAlertTrdr(prev => !prev)
        })

        return () => {
            socket.disconnect();
        };
    }, [])

    return (<>
        <div style={{ display: "none" }}>
            <NotificationSong trigger={alertTradingview} platform={'tradingView'} />
        </div>
        <div style={{ display: "none" }}>
            <NotificationSong trigger={alertTrdr} platform={'trdr'} />
        </div>
    </>);
}

export default NotificationSongGlobal;