import {
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text
} from "@chakra-ui/react";

import ModalDeleteUser from '../ModalDeleteUser/ModalDeleteUser'

import { ChevronDownIcon } from "@chakra-ui/icons";
import { IoDuplicateSharp, IoSaveSharp } from 'react-icons/io5'
import { SlPencil } from 'react-icons/sl'
import { useState } from "react";
import { FiDelete } from "react-icons/fi";
// import ModalNewStrategie from "../ModalNewStrategie/ModalNewStrategie";
import { useEffect } from "react";
// import ModalDeleteStrategie from "../ModalDeleteStrategie/ModalDeleteStrategie";
// import ModalIndicators from "../ModalIndicators/ModalIndicators";

const MenuActions = ({ id, email, deleteUser, checkAccount, resetPassword, changeUserType, type, removeDiscordId  }) => {

    return (
        <>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    bg={'header'}
                    // onClick={onOpen}
                    color='white'
                    _hover={{ bg: 'secondary', color: 'activeGreen' }}
                    _active={{
                        backgroundColor: 'header'
                    }}
                >
                    Ações
                </MenuButton>
                <MenuList bgColor={'primary'} border={'1px solid white'} minWidth={'60px'} zIndex={'300'}>
                    <MenuItem fontFamily="poppins" bgColor={'primary'}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <ModalDeleteUser name={'Deletar'} id={id} handleDeleteUser={deleteUser}/>
                            {/* <Text marginLeft={'5px'}>Deletar</Text> */}
                        </Flex>
                    </MenuItem>
                    <MenuItem 
                        fontFamily="poppins" 
                        bgColor={'primary'}
                        onClick={() => changeUserType(id)}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <Text marginLeft={'5px'}>Tornar {`${type === 'senior' ? 'Common': 'Sênior'}`}</Text>
                        </Flex>
                    </MenuItem>
                    <MenuItem 
                        fontFamily="poppins" 
                        bgColor={'primary'}
                        onClick={() => checkAccount(email)}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <Text marginLeft={'5px'}>Enviar email de verificação</Text>
                        </Flex>
                    </MenuItem>
                    <MenuItem 
                        fontFamily="poppins" 
                        bgColor={'primary'} 
                        onClick={() => resetPassword(email)}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <Text marginLeft={'5px'}>Enviar email para recuperar senha</Text>
                        </Flex>
                    </MenuItem>
                    <MenuItem 
                        fontFamily="poppins" 
                        bgColor={'primary'} 
                        onClick={() => removeDiscordId(id)}>
                        <Flex>
                            <IoSaveSharp size={'16px'} />
                            <Text marginLeft={'5px'}>Remover discord</Text>
                        </Flex>
                    </MenuItem>
                    {/* <MenuItem fontFamily="poppins" bgColor={'primary'}>
                        <ModalDeleteStrategie
                            name='Excluir'
                            icon={<FiDelete size={'16px'} />}
                            deleteStrategie={handleRemove} />
                    </MenuItem> */}
                </MenuList>
            </Menu>
        </>
    )
}

export default MenuActions;

