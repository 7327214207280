import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { FiFilter } from "react-icons/fi";


const GenericFilter = ({ saveFilter, column, clearFilter, clickedState, handleClickInFilter }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [configFilter, setConfigFilter] = useState()

    const save = (column, value) => {
        setConfigFilter({ ...configFilter, [column]: value })
    }

    function saveConfig() {
        saveFilter(configFilter, column)
        handleClickInFilter(!clickedState)
        onClose()
    }

    function clearConfig() {
        setConfigFilter()
        clearFilter(configFilter, column)
        handleClickInFilter(!clickedState)
        onClose()
    }

    return (
        <>
            {!configFilter
                ? (<FiFilter
                    fontSize='18px'
                    cursor='pointer'
                    onClick={onOpen}
                    transition='0.2s'
                    z-index='200'
                />) : (
                    <FiFilter
                        fontSize='18px'
                        cursor='pointer'
                        onClick={onOpen}
                        transition='0.2s'
                        z-index='200'
                        color="red"
                    />)
            }


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent width={'300px'} bg={'primary'} border={'1px solid white'}>
                    <ModalHeader color={'white'}>Filtrar por {column}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Flex flexDirection={'column'} alignItems={'center'} >
                            {column === 'Símbolos' ? (<></>) : (
                                <Select width={200} marginY={5} color={'white'} bgColor={'primary'} onChange={(e) => save('filter', e.target.value)}>
                                    <option value='<' style={{ backgroundColor: "black" }}>Selecione seu filtro</option>
                                    <option value='<' style={{ backgroundColor: "black" }}>Menor que</option>
                                    <option value='<=' style={{ backgroundColor: "black" }}>Menor ou igual a</option>
                                    <option value='>' style={{ backgroundColor: "black" }}>Maior que</option>
                                    <option value='>=' style={{ backgroundColor: "black" }}>Maior ou igual a</option>
                                </Select>
                            )}
                            <Input marginY={5} placeholder={column !== 'Symbol' ? 'Ex: 10' : 'BTC'} width={200} color={'text'} onChange={(e) => setConfigFilter({ ...configFilter, 'value': e.target.value })} />
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex>
                            <Button colorScheme='blue' mr={3} onClick={() => clearConfig()}>
                                Limpar
                            </Button>
                            <Button color={'black'} onClick={() => saveConfig()}>
                                Aplicar
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    );
}

export default GenericFilter;