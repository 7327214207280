

import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NotificationSong from "../../components/NotificationSong/NotificationSong";
import alerts from "../../services/alerts";
import openSocket from "../../services/socket-io";
// import EmBreve from "../../components/EmBreve/Embreve";
import TablePerson from "./Table/Table";
import styles from './Trdr.module.css'

const Trdr = () => {
    // return (
    //     <div className={styles.container}>
    //         <div style={{
    //             display: 'flex',
    //             flexDirection: 'column',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             height: '70%'

    //         }}>
    //             <EmBreve />
    //         </div>
    //     </div>
    // )
    const columnsFix = {
        'Moeda': false,
        'Nome': false,
        'Hora': false,
        'Preço': false,
        'Long/Short': false
    }

    const [data, setData] = useState([])
    const [columns, setColumns] = useState(columnsFix)
    const [alert, setAlert] = useState(false)

    function getAlerts() {
        alerts.getAlertsTrdr()
            .then(resp => {
                let responseApi = resp.data
                setData(responseApi)
            })
            .catch(err => console.log(err))
    }
    useEffect(() => {
        getAlerts()
    }, [])

    useEffect(() => {
        const socket = openSocket();

        socket.emit('client-conected', () => {
            console.log('Client connected. ')
        })

        socket.on('alertTrdr', (alrt) => {
            if (!data.length) {
                setAlert(prev => !prev)
                getAlerts()
                // setData([...data, alert.alertTrdr])
            }
        })

        return () => {
            socket.disconnect();
        };
    }, [])

    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='5px'>
                        HUNTER
                    </Text>
                    <NotificationSong trigger={alert} platform={'strategiesalert'} />
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}
                        zIndex={200}>
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} dataTable={data} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default Trdr;