import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import styles from './Fuding.module.css'
import TablePerson from "./Table/Table";
import { useState } from "react";
import { useEffect } from "react";
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import TableData from "../../services/TableDataService";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";



const Fuding = ({ updateNow }) => {
    const columnsFix = {
        'Símbolos': false,
        'Funding Rate': false,
    }

    const [data, setData] = useState(JSON.parse(localStorage.getItem('FundingPage')))
    const [updatedPage, setUpdatedPage] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    const [columns, setColumns] = useState(columnsFix)
    const [order, setOrder] = useState(true)
    const [symbols, setSymbols] = useState([])
    const [updateTable, setUpdateTable] = useState(false)
    const [timer, setTimer] = useState(false)

    function updateSymbols(newData, column) {
        let newColumnsConfig = columnsFix
        newColumnsConfig[column] = columns[column] ? false : true
        setOrder(!order)
        setColumns(newColumnsConfig)
        setData(newData)
    }

    function handleFilterTable(newData) {
        setUpdateTable(!updateTable)
        setTimeout(() => {
            setUpdateTable(true)
        }, 100)
        setData(newData)
    }

    function formatData(data, symbol) {
        console.log('data - formatData')
        console.log(data)
        setLastUpdatedAt(new Date(data['FR'][0].createdAt))
        // symbol = symbol.map(e => e.symbol)
        let symbols = []
        let newData = { 'Funding Rate': {}, symbols: symbols }
        data['FR'].map(d => {
            symbols.push(d.symbol)
            newData['Funding Rate'] = { ...newData['Funding Rate'], [d.symbol]: parseFloat(d['FR']) }
        })

        console.log(newData)
        return newData
    }
    // async function getData(symbolsData) {

    //     const url = 'https://fapi.binance.com/fapi/v1/fundingRate'
    //     let newDatas = {}
    //     symbolsData.map(async (symbol) => {
    //         axios.get(
    //             `${url}?symbol=${symbol.toUpperCase()}USDT`
    //         ).then((resp) => {
    //             newDatas[symbol] = resp.data
    //         }).catch(err => console.log(err))
    //     })

    //     return { 'Funding Rate': newDatas }
    // }

    // function prepareData(fdr, symbolsData) {
    //     let newData = {
    //         'Funding Rate': {},
    //     }
    //     symbolsData.map((symbol) => {
    //         let fdrBySymbol = (parseFloat(fdr['Funding Rate'][symbol].at(-1)['fundingRate']) * 100).toFixed(4)
    //         newData['Funding Rate'] = { ...newData['Funding Rate'], [symbol]: fdrBySymbol }
    //     })


    //     setUpdateTable(true)
    //     return newData
    // }

    function OrderByFr(dataTable) {
        dataTable[0].symbols = dataTable[0].symbols.sort((a, b) => {
            if (parseFloat(dataTable[1]['Funding Rate'][a]) > parseFloat(dataTable[1]['Funding Rate'][b]))
                return 1
            if (parseFloat(dataTable[1]['Funding Rate'][a]) < parseFloat(dataTable[1]['Funding Rate'][b]))
                return -1
        }).reverse()
        return dataTable
    }

    useEffect(() => {
        async function fetchData() {
            let symbols = await TableData.getSymbols()
            let response = await TableData.getTableData('FR')
            let dataFormated = formatData(response['data'], symbols['data'])
            localStorage.setItem('FundingPage', JSON.stringify(dataFormated))
            setData(dataFormated)
            setUpdateTable(true)
            // axios.get('https://fapi.binance.com/fapi/v1/ticker/price')
            //     .then(async (resp) => {
            //         const correctSymbols = resp.data
            //             .filter((item) => item.symbol.includes('USDT'))
            //             .map((item) => item.symbol.replace('USDT', ''))
            //             .filter(
            //                 (item) => UNAVAILABLE_SYMBOLS.indexOf(item.toLocaleLowerCase()) === -1
            //             );
            //         setSymbols(correctSymbols)
            //         localStorage.setItem('symbols', JSON.stringify(correctSymbols))
            //         let fdr = await getData(correctSymbols, '5m')

            //         setTimeout(() => {
            //             let prepareDateTable = prepareData(fdr, correctSymbols)
            //             let newData = [
            //                 { 'symbols': correctSymbols },
            //                 { 'Funding Rate': prepareDateTable["Funding Rate"] }
            //             ]
            //             setData(OrderByFr(newData))
            //         }, 4000)

            //         // await getData(correctSymbols, '1h')
            //         // await getData(correctSymbols, '4h')
            //         // await getData(correctSymbols, '24h')
            //     })
            //     .catch(err => console.log(err))


            // setData({ ...data, '15m': await prepareData() })
        }

        fetchData()
        // if (updateNow) {
        //     setUpdateTable(false)
        //     fetchData()
        //     setTimeout(() => {
        //         setUpdateTable(true);
        //     }, 4000)
        // }
    }, [updatedPage])
    // }, [, updateNow])



    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Flex flexDirection={'column'} alignItems={'center'}>
                        <Text
                            fontSize='32px'
                            color='white'
                            fontWeight='bolder'
                            margin='10px'>
                            Funding Rate
                        </Text>
                    </Flex>
                    {/* <div>
                        <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                            <Timer
                                loadData={data}
                                handleUpdateTable={setTimer}
                                statusTimer={timer}
                                actionUpdateTable={setUpdateTable} />
                        </Flex>
                    </div> */}
                    <NotificationSongGlobal />
                    <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} symbols={symbols}
                                dataTable={data} handleUpdateSymbols={updateSymbols}
                                updateTable={updateTable} order={order} handleFilterTable={handleFilterTable} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default Fuding;