import { FiSettings } from 'react-icons/fi'
import { Text } from "@chakra-ui/react";

const EmBreve = () => {
    return (<>
        <FiSettings fontSize={'50px'} color='white' />
        <Text
            marginTop={'10px'}
            fontSize={'20px'}
            textAlign={'center'}
            verticalAlign={'center'}
            color={'text'}
            fontWeight={'700'}
            fontFamily={'heading'}>
            Em Breve...
        </Text>
    </>
    );
}

export default EmBreve;