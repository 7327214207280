import EmBreve from '../../components/EmBreve/Embreve';
import styles from './OrderBookDeep.module.css'

const OrderBookDeep = () => {
    return (
        <div className={styles.container}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70%'

            }}>
                <EmBreve />
            </div>
        </div>
    );
}

export default OrderBookDeep;