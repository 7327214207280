import { api, requestConfig } from "../utils/config";


// Create a strategie
const createRadar = async (data) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("POST", data, token);
  try {
    const res = fetch(`${api}/radar`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => {
        console.log('err')
        console.log(err)
        return {
          errors: ["Houve um erro, por favor tente mais tarde."],
        }
      });

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Update a strategie
const updateRadar = async (data, name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("PUT", data, token);

  try {
    const res = fetch(`${api}/radar/${name}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Delete a strategie
const deleteRadar = async (name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("DELETE", null, token);

  try {
    const res = fetch(`${api}/radar/${name}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log(error);
  }
};

// get strategie by name
const getRadar = async (name) => {
  let token = localStorage.getItem('token')
  const config = requestConfig("GET", null, token);
  try {
    const res = fetch(`${api}/radar${name ? '/' + name : ''}`, config)
      .then((res) => {
        const statusCode = res.status;
        return res.json().then((obj) => ({
          statusCode: statusCode,
          data: obj,
        }));
      })
      .catch((err) => ({
        errors: ["Houve um erro, por favor tente mais tarde."],
      }));

    return res;
  } catch (error) {
    console.log('error - getRadar');
    console.log(error);
  }
};

const userService = {
  createRadar,
  updateRadar,
  deleteRadar,
  getRadar
};

export default userService;
