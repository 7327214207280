import { Avatar, Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import styles from './NavBar.module.css'
import Header from "../Header/Header";
import Logo from '../../assets/logo-header.ico'
import UserIcon from '../../assets/USER.png'
import ConnectSe from '../../assets/connecteSe.png'
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from "react";
import { FilterContext } from "../../hooks/Filter/Filter";
import { AuthContext } from "../../hooks/Auth/";
import { PermissionContext } from "../../hooks/Permission/";
import TableData from "../../services/TableDataService";
import { GiPadlock } from 'react-icons/gi'
import { toast } from "react-toastify";
import ModalPermission from "./Modal";

const NavBar = () => {
    let user = JSON.parse(localStorage.getItem('research-user-data'))
    const { clearFilter, defineBeforeData, setFilterData } = useContext(FilterContext)
    const { authToken, Logout, } = useContext(AuthContext)
    const { LoadPermission } = useContext(PermissionContext)
    const [userLoggedin, setUserLoggedin] = useState(true)
    const [userData, setUserData] = useState({})
    const [userPermissions, setUserPermissions] = useState([])
    const [currentModal, setCurrentModal] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            let user = JSON.parse(localStorage.getItem('research-user-data'))
            console.log('useEffect')
            // fazendo uma requisição qualquer para uma url q precisa de autenticação
            let response = await TableData.getTableData('Lsr')
            let token = localStorage.getItem('token')
            setUserData(user)
            await LoadPermission(user?.discordId)
            setUserPermissions(
                localStorage.getItem('permissions') ? 
                JSON.parse(localStorage.getItem('permissions')) : 
                []
            )
            if (response.statusCode !== 401) {
                setUserLoggedin(true)
            } else {
                if (token) {
                    navigate('/login')
                    return
                }
                setUserLoggedin(false)
            }
        }

        fetchData()
    }, [, authToken])

    function handleClickClearFilter() {
        clearFilter()
        defineBeforeData()
        setFilterData()
    }

    function handelClickLogout(e) {
        Logout()

        setTimeout(() => {
            navigate('/login')
            setUserLoggedin(false)
        }, 1500);
    }

    function handleModals(page) {
        const permissions = [
            'associado','estagiario','trial','research',
            'trader','traderSenior','suporte','orientador']
        console.log('userPermissions')
        console.log(userPermissions)

        let isOnServer = userPermissions.length ? userPermissions.filter(p => Object.keys(p)[0] === 'isOnServer') : []
        if(userData && !userData.discordId){
            setCurrentModal('linkToDiscord')
        }
        else if(isOnServer.length && isOnServer[0].isOnServer){
            let hasPermission = false
            for (const permission of userPermissions) {
                let permissionsEntries = Object.entries(permission)
                if (
                    permissionsEntries[0][1] &&
                    permissions.includes(permissionsEntries[0][0])) {
                    console.log('entrei ?')
                    hasPermission = true
                }
            }
            if(!hasPermission){
                setCurrentModal('check')
            } else {
                navigate(`/${page}`)
            }
        } 
        else {
            setCurrentModal('enterServer')
        }
          
        
    }

    function isBlock() {
        const permissions = [
            'associado','estagiario','trial','research',
            'trader','traderSenior','suporte','orientador']
        let isOnServer = userPermissions.length ? userPermissions.filter(p => Object.keys(p)[0] === 'isOnServer') : []
        
        if(userData && !userData.discordId){
            return <GiPadlock color='red'/>
        }
        else if(isOnServer.length && isOnServer[0].isOnServer){
            let hasPermission = false
            for (const permission of userPermissions) {
                let permissionsEntries = Object.entries(permission)
                if (
                    permissionsEntries[0][1] &&
                    permissions.includes(permissionsEntries[0][0])) {
                    hasPermission = true
                }
            }
            if(!hasPermission){
                return <GiPadlock color='red'/>
            } else {
                return <></>
            }
        } else {
            return <GiPadlock color='red'/>
        }
          
        
    }
    
    function handleCloseModal(){
        setCurrentModal('')
    }
    // function handelClickLinkToDiscord(e) {
    //     setTimeout(() => {
    //         navigate('/discord')
    //         setUserLoggedin(false)
    //     }, 1500);
    // }

    return (
        !userLoggedin ?
            (< Header bgColor={'#242424'} boxShadow={'0 5px 10px 0px rgba(10, 10, 10, 1)'} >
                <Flex display={'flex'} justifyContent="space-between" height="70px" padding="0x 5vw">
                    <img src={Logo} alt="Research logo" width="13%" height="100%" />
                    <Flex
                        color={'text'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'30%'}>

                        <Flex className={styles.itemContainer}>
                            <Menu>
                                <Link to='/register'>
                                    <MenuButton as={Button}
                                        fontFamily="poppins"
                                        bg={'heade'}
                                        _hover={{
                                            textColor: "activeGreen"
                                        }}
                                        _active={{
                                            backgroundColor: 'heade'
                                        }}>
                                        Cadastro
                                    </MenuButton>
                                </Link>
                            </Menu>
                        </Flex>
                        <Flex className={styles.itemContainer}>
                            <Menu>
                                <Link to='/login'>
                                    <MenuButton
                                        as={Button}
                                        fontFamily="poppins"
                                        bg={'heade'}
                                        _hover={{
                                            textColor: "activeGreen"
                                        }}
                                        _active={{
                                            backgroundColor: 'heade'
                                        }}>
                                        <Box display={'flex'} flexDirection={'columns'} >
                                            Login
                                            <Image src={ConnectSe} w={'19px'} marginLeft={'5px'} />
                                        </Box>
                                    </MenuButton>
                                </Link>
                            </Menu>
                        </Flex>
                        <Box marginLeft={'15px'}>
                            <Image src={UserIcon} w={'35px'} />
                            {/* <AiOutlineUser fontSize="35px" color="text" cursor="pointer" /> */}
                        </Box>
                    </Flex >

                </Flex >
            </Header >) : (
                <Header backgroundColor={"primary"} boxShadow={'0 5px 10px 0px rgba(10, 10, 10, 1)'}>
                {currentModal ? <ModalPermission 
                    dataKey={currentModal}
                    openModal={true}
                    closeModal={handleCloseModal}/>: <></>   
                } 
                < Flex align="center" height="70px" padding="0x 5vw" justifyContent="space-between" >
                        <Box>
                            <Link to="/longShortRatio">
                                <Image
                                    src={Logo} alt='Research'
                                    width="95%" height="100%"
                                    transition={'0.3s'}
                                    _hover={{ width: '100%' }} />
                            </Link>
                        </Box>
                        <Flex className={styles.itemsContainer} color={'text'}>
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        fontFamily="poppins"
                                        bg={'heade'}
                                        _hover={{
                                            textColor: "activeGreen"
                                        }}
                                        _active={{
                                            backgroundColor: 'heade'
                                        }}
                                    >
                                        Indicadores de sentimento
                                    </MenuButton>
                                    <MenuList bgColor={'rgba(40, 40, 40, 0.77)'} zIndex={'300'}>
                                        <Link to="/longShortRatio">
                                            <MenuItem fontFamily="poppins">
                                                Long Short Ratio
                                            </MenuItem>
                                        </Link>
                                        <Link to="/openInterest">
                                            <MenuItem fontFamily="poppins">
                                            Open Interest
                                            </MenuItem>
                                        </Link>
                                       { /* <Link to={`/${userData?.discordId ? 'marketVolume' : 'discord'}`} >*/}
                                        <MenuItem 
                                            fontFamily="poppins" 
                                            onClick={() => handleModals('marketVolume')}>
                                            Market Volume
                                            &nbsp;
                                            {isBlock()}
                                        </MenuItem>
                                        <MenuItem 
                                            fontFamily="poppins"
                                            onClick={() => handleModals('funding')}>
                                            Funding Rate
                                            &nbsp;
                                            {isBlock()}
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex >
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                    <MenuButton as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        fontFamily="poppins"
                                        bg={'heade'}
                                        _hover={{
                                            textColor: "activeGreen"
                                        }}
                                        _active={{
                                            backgroundColor: 'heade'
                                        }}>
                                        Indicadores técnicos
                                    </MenuButton>
                                    <MenuList fontFamily="poppins" bgColor={'rgba(40, 40, 40, 0.77)'} zIndex={'300'}>
                                        <Link to='/rsi'>
                                            <MenuItem fontFamily="poppins">RSI</MenuItem>
                                        </Link>
                                        <Link to='/macd'>
                                            <MenuItem fontFamily="poppins">
                                                MACD
                                            </MenuItem>
                                        </Link>
                                    </MenuList>
                                </Menu>
                            </Flex>
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                    <Link to='/marketcap'>
                                        <MenuButton as={Button}
                                            fontFamily="poppins"
                                            bg={'heade'}
                                            _hover={{
                                                textColor: "activeGreen"
                                            }}
                                            _active={{
                                                backgroundColor: 'heade'
                                            }}>
                                            Marketcap
                                        </MenuButton>
                                    </Link>
                                </Menu>
                            </Flex>
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                        <MenuButton as={Button}
                                            onClick={() => handleModals('strategies')}
                                            fontFamily="poppins"
                                            bg={'heade'}
                                            _hover={{
                                                textColor: "activeGreen"
                                            }}
                                            _active={{
                                                backgroundColor: 'heade'
                                            }}>
                                            <Flex>
                                                Estratégias 
                                                &nbsp;
                                                {isBlock()}
                                            </Flex>
                                        </MenuButton>
                                </Menu>
                            </Flex>
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                        <MenuButton 
                                            as={Button}
                                            onClick={() => handleModals('radar')}
                                            fontFamily="poppins"
                                            bg={'heade'}
                                            _hover={{
                                                textColor: "activeGreen"
                                            }}
                                            _active={{
                                                backgroundColor: 'heade'}}
                                            >
                                            <Flex>
                                                Radar 
                                                &nbsp;
                                                {isBlock()}
                                            </Flex>
                                        </MenuButton>
                                </Menu>
                            </Flex>
                            <Flex className={styles.itemContainer}>
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        fontFamily="poppins"
                                        bg={'heade'}
                                        _hover={{
                                            textColor: "activeGreen"
                                        }}
                                        _active={{
                                            backgroundColor: 'heade'
                                        }}
                                    >
                                        Alertas
                                    </MenuButton>
                                    <MenuList bgColor={'rgba(40, 40, 40, 0.77)'}>
                                            <MenuItem 
                                                onClick={() => { 
                                                    handleModals('trdr')
                                                    handleClickClearFilter()
                                                 }}
                                                fontFamily="poppins">
                                                <Flex>
                                                    Hunter    
                                                </Flex>
                                                &nbsp;
                                                {isBlock()}
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={() => { 
                                                    handleModals('tradingview')
                                                    handleClickClearFilter() }}
                                                fontFamily="poppins">
                                                <Flex>
                                                    Equipe Belo Trade
                                                </Flex>
                                                &nbsp;
                                                {isBlock()}
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={() => { 
                                                    handleModals('alert-strategie')
                                                    handleClickClearFilter() 
                                                }}
                                                fontFamily="poppins">
                                                <Flex flexDirection={'column'}>
                                                    Estratégias 
                                                </Flex>
                                                &nbsp;
                                                {isBlock()}
                                            </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </Flex >
                        <Menu>
                            <MenuButton
                                as={Avatar}
                                src={user && user.photo 
                                    ? `${process.env.REACT_APP_URL_BACKEND}/uploads/${user.photo}`
                                    : 'https://bit.ly/dan-abramov'}
                                cursor="pointer" />
                            <MenuList minW="125px">
                                <Link to="/profile">
                                    <MenuItem>Perfil</MenuItem>
                                </Link>
                                {userData && userData.type === 'senior' ? <Link to="/admin">
                                    <MenuItem>Gestão de usuários</MenuItem>
                                </Link>
                                : <></>}
                                <MenuItem onClick={handelClickLogout}>Sair</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex >
                </Header >
            )

    )
}

export default NavBar;
