import { Flex, Table } from '@chakra-ui/react'
import { TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import dayjs from 'dayjs'
import { useEffect } from 'react';
import styles from './Table.module.css'

const TablePerson = ({ columns, dataTable }) => {

    useEffect(() => {
        // console.log('dataTable')
        // console.log(dataTable)
    }, [dataTable])

    return (<>
        <TableContainer borderRadius={30} overflowX={'hidden'} >
            <Table variant={'unstyled'} textColor={'text'}>
                <Thead bg={'secondary'}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            // onClick={() => { DataOrderingByColumn(th) }}
                            <Th key={th}
                                textColor={'text'}
                                _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                                w={150}
                                h={50}>
                                <Flex justifyContent={'center'}>
                                    <span className={styles.valueTable}>{th}</span>
                                </Flex>

                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody overflowX={'hidden'} overflowY={'auto'}>
                    {dataTable ? (dataTable.map((row, index) => <Tr key={index}>
                        <Td>
                            {(row['moeda'])}
                        </Td>
                        <Td>
                            {(row['nome'].length > 26 ? row['nome'].slice(0, 26) + '...' : row['nome'])}
                        </Td>
                        <Td>
                            {dayjs(row['createdAt']).format("DD/MM HH:mm")}
                        </Td>
                        <Td>
                            {(row['preco'])}
                        </Td>
                    </Tr>)) : <Tr>
                        <Td></Td><Td></Td>
                        <Td></Td><Td></Td>
                        <Td></Td><Td></Td>
                    </Tr>}
                </Tbody>
                <Tfoot bg={'secondary'} borderRadius={30}>
                    <Tr>
                        {Object.keys(columns).map(th => (
                            <Th key={th}></Th>
                        ))}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer >
    </>

    );
}

export default TablePerson;