import { Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import * as U from '../../utils/Timer'
import styles from './GenericTimer.module.css'

export function GenericTimer({ lastUpdatedAt, updateTable }) {

  let [timing, setTiming] = useState(0)
  let [updating, setUpdating] = useState(true)
  // let [error, setError] = useState(false)

  const hours = Math.floor(timing / 3600);
  const minutes = Math.floor(timing / 60);
  const seconds = timing % 60;

  const startTimer = () => {
    setTimeout(() => {
      if (timing > 0) {
        setTiming(timing -= 1);
      } else {
        setUpdating(true)
        setTimeout(() => {
          setUpdating(false)
          let lastDate = new Date().getTime() - lastUpdatedAt.getTime()
          let newTiming = 600 - Math.floor(lastDate / 1000) - 5
          if (newTiming < 0) {
            setTiming(600)
            updateTable(prev => !prev)
          } else {
            setTiming(newTiming)
            updateTable(prev => !prev)
          }
        }, 1000)


        // setTimeout(() => {
        //   setUpdating(false)
        //   let lastDate = new Date().getTime() - lastUpdatedAt.getTime()
        //   let newTiming = 20 - Math.floor(lastDate / 1000)
        //   setTiming(newTiming)
        //   setTimingStarted(true)
        //   updateTable(prev => !prev)
        // }, 100)
      }
    }, 1000)
  }


  // const calculaFracao = () => {
  //     return tempoRestante / TEMPO_TOTAL;
  // }

  useEffect(() => {
    // let hr = moment(lastUpdatedAt).add(5, 'minutes')
    // console.log(new Date(hr))

    if (!lastUpdatedAt)
      return
    else
      startTimer()
  }, [lastUpdatedAt, timing])

  return (
    <div className={styles.Container}>
      <Flex alignItems={'flex-start'} fontFamily={"roboto"}>
        {updating ? (
          <Text
            fontSize={'14px'}
            textAlign={'center'}
            color={'text'}
            fontWeight={'700'}
            fontFamily={'roboto'}>
            Atualizando...
          </Text>
        ) : (
          <>
            {lastUpdatedAt && <Text
              fontSize={'14px'}
              textAlign={'center'}
              color={'text'}
              fontWeight={'500'}
              fontFamily={'roboto'}
              marginRight={'15px'}>
              Atualizado às {U.formatHour(lastUpdatedAt)}
            </Text>}
            <Text
              fontSize={'14px'}
              textAlign={'center'}
              color={'text'}
              fontWeight={'700'}
              fontFamily={'roboto'}>
              {U.formatTime(hours)}:{U.formatTime(minutes)}:
              {U.formatTime(seconds)}
            </Text>
          </>
        )}
      </Flex>
    </div >
  )
}

