import { Avatar, Box, Button, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import authService from '../../services/authService';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import userService from '../../services/userService';


const Profile = () => {

    let hasPermissions = localStorage.getItem('permissions')
    let user = JSON.parse(localStorage.getItem('research-user-data'))
    const [permissions, setPermissions] = useState(
            hasPermissions 
                ? JSON.parse(hasPermissions) 
                : [])
    const [loading, setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [dataToUpdate, setDataToUpdate] = useState({username: user.username})
    const [photo, setPhoto] = useState(user.photo 
        ? 
            user.photo.startsWith('http') 
                ? `${user.photo}`
                : `${process.env.REACT_APP_URL_BACKEND}/uploads/${user.photo}`
        : 'https://bit.ly/dan-abramov');

    async function handelClickForgotPassword() {
        let user = JSON.parse(localStorage.getItem('research-user-data'))
        setLoading(true)
        let response = await authService.sendEmailForgotPasswordCode({ email: user.email  })

        if (response.statusCode === 201 || response.statusCode === 200) {
            toast.success('Enviamos um link para redefinição da senha no seu email. Lembre-se de checar sua caixa de span. ')
        } else {
            setLoading(false)
            toast.error(response.message || response.error)
        }
        setLoading(false)
    }

    async function handelClickUpdateData() {
        let user = JSON.parse(localStorage.getItem('research-user-data'))
        setLoadingUpdate(true)
        let response = await userService.updateUser(user.id, dataToUpdate)

        if (response.statusCode === 201 || response.statusCode === 200) {
            user.username = dataToUpdate.username
            localStorage.setItem('research-user-data', JSON.stringify(user))
            toast.success('Dados atualizados com sucesso')
        } else {
            setLoading(false)
            toast.error(response.message || response.error)
        }
        setLoadingUpdate(false)
    }

    const handlePhotoChange = async (e) => {
        const file = e.target.files[0];
        console.log(file)
        const formData = new FormData();
        formData.append("profile", file);
        // formData.append("body", file.name);
        console.log(formData)
        let responsUpdate = await userService.updateUser(user.id, formData)
        console.log('responsUpdate')
        console.log(responsUpdate)
        if (responsUpdate.statusCode > 400) {
            toast.error('Erro ao atualizar a foto. ')
        } else {
            user.photo = `${process.env.REACT_APP_URL_BACKEND}/uploads/${responsUpdate['data']['filename']}`
            localStorage.setItem('research-user-data', JSON.stringify(user))
            toast.success('Foto atualizada com sucesso. ')
            setPhoto(user.photo)
        }

      };

    return (
    <Box 
        maxW={'100%'} 
        h={'100vh'}
        backgroundColor={'#000'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'#fff'}
        >
        <Box
            overflowY={'auto'}
            // backgroundColor={'black'}
            margin={'15px'}
            w={'500px'}
            h={'700px'}
            border={'4px solid #9400D3'}
            display={'flex'}
            flexDirection={'column'}
            borderRadius={'3px'}
            padding={'20px'}
            // justifyContent={'center'}
            alignItems={'center'}
            boxSizing={'border-box'}
        >   
             
             <Box position={'relative'} display="inline-block">
                <Avatar width={'300px'} height={'300px'}  name='Dan Abrahmov' src={photo}/>
                <input 
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                    }}
                    type="file" 
                    accept="image/*" 
                    onChange={handlePhotoChange} 
                    />
             </Box>
             <Text 
                marginTop={'20px'} 
                width={'100%'} 
                align={'start'} 
                fontWeight={'bold'}>Permissões:</Text>
             <Box 
                marginTop={'5px'} 
                width={'100%'}  
                display={'flex'} 
                justifyContent={'start'} 
                flexWrap={'wrap'}> 
                {
                    permissions.length 
                        ? permissions.map((permission, indice) => (   
                            (
                                permission[Object.keys(permission)] && 
                                Object.keys(permission)[0] !== 'isOnServer' ) ? (
                                <Box 
                                    margin={'3px'}
                                    key={indice}
                                    padding={'3px'}
                                    borderRadius={'4px'}
                                    textAlign={'center'}
                                    maxWidth={'150px'} 
                                    height={'30px'} 
                                    border={(indice%2 === 0) ? '2px solid #39ff14': '2px solid #9400D3'}>
                                    {Object.keys(permission)[0]}
                                </Box>) : (<></>)             
                            ))
                        : (<Box 
                            padding={'3px'}
                            borderRadius={'4px'}
                            textAlign={'center'}
                            maxWidth={'150px'} 
                            height={'30px'} 
                            border={'2px solid gray'}>
                                Sem permissões
                            </Box>)
                }
             <Box 
                marginTop={'20px'}
                width={'100%'}  >
                <Box>
                    <Text fontWeight={'bold'}>
                        Username: 
                    </Text>
                    <Input 
                        color={'white'} 
                        type='text' 
                        value={dataToUpdate.username} 
                        onChange={(e) => setDataToUpdate({...dataToUpdate,'username': e.target.value})}/>
                </Box>
                <Box marginTop={'15px'}>
                    <Text fontWeight={'bold'}>
                        Email: 
                    </Text>
                    <Input color={'white'} type='text' value={user.email} disabled/>
                </Box>
                <Box marginTop={'15px'}>
                    <Text fontWeight={'bold'}>
                        Discord Vinculado
                    </Text>
                    <Input color={'white'} type='text' value={user.discordId} disabled/>
                </Box>
                <Box marginTop={'15px'}>
                    <Button 
                        onClick={handelClickForgotPassword}
                        width={'100%'}
                        backgroundColor={'transparent'} 
                        border={'1px solid gray'}
                        _hover={{ backgroundColor: '#ffffff47', color: 'activeGreen'}}>
                        {loading ? <Spinner /> : 'Redefinir senha'}
                    </Button>
                </Box>
                <Box marginTop={'15px'}>
                    <Button 
                        cursor={!!dataToUpdate?.username ? 'pointer': 'not-allowed'}
                        disabled={dataToUpdate.username === user.username}
                        onClick={handelClickUpdateData}
                        width={'100%'}
                        backgroundColor={'transparent'} 
                        border={'1px solid gray'}
                        _hover={{ backgroundColor: '#ffffff47', color: 'activeGreen'}}>
                        {loadingUpdate ? <Spinner /> : 'Atualizar'}
                    </Button>
                </Box>
             </Box>
        </Box>
        </Box>
    </Box>)
}

export default Profile;