import { chakra } from "@chakra-ui/react";

// TODO:fazer o media query
const Header = chakra('header', {
    baseStyle: {
        alignItems: "center",
        height: "70px",
        padding: "0px 5vw",
        justifyContent: "space-between",
        boxShadow: "0 5px 10px 0px rgba(10, 10, 10, 1)",
        backgroundColor: "red.200"

    }
})

export default Header;