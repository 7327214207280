import { ChevronDownIcon } from "@chakra-ui/icons"
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Text,
} from "@chakra-ui/react"
import strategieService from '../../../../services/strategiesService'
import { useState } from "react"
import { useEffect } from "react"

const ModalStrategies = ({ icon, setStrategie }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [strategies, setStrategies] = useState([])

    async function getStrategies() {
        try {
            let strategiesData = await strategieService.getStrategies()
            setTimeout(() => {
                setStrategies(strategiesData['data']['strategies'])
            }, 500)
            onClose()
        } catch (error) {
            alert('Ocorreu algo de errado. ')
        }

    }

    function handleNewTable(name) {
        setStrategie(name)
        onClose()
    }

    useEffect(() => {
        getStrategies()
    }, [])

    return (<>
        <Button
            marginTop={'5px'}
            marginBottom={'5px'}
            marginRight={'10px'}
            marginLeft={'5px'}
            bg='header'
            color='white'
            _hover={{ bg: 'secondary', color: 'activeGreen' }}
            onClick={onOpen}
            cursor={'pointer'}>
            Selecionar estratégia
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bgColor={'black'}>
                <ModalHeader color={'white'}>Carregar modelo</ModalHeader>
                <ModalCloseButton color={'white'} />
                <ModalBody>
                    {strategies.length ? strategies.map(strategie => (
                        <Text
                            key={strategie.id}
                            padding={'10px'}
                            fontWeight={'bolder'}
                            color={'white'}
                            cursor={'pointer'}
                            borderTop={'1px solid #ffffffa1'}
                            borderBottom={'1px solid #ffffffa1'}
                            _hover={{ backgroundColor: 'rgba(100, 100, 100, 0.4)' }}
                            onClick={() => {
                                handleNewTable(strategie.name)
                            }}
                        >
                            {strategie.name}
                        </Text>
                    )) : <Text
                        fontWeight={'bolder'}
                        color={'white'}> Não há estratégias no momento...
                    </Text>
                    }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>);
}

export default ModalStrategies;