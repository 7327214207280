import { Box, Image, Text } from "@chakra-ui/react";
import BeloTradeLogoFooter from '../../assets/BELOTRADE_LOGO_FOOTER.png'
const Footer = () => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            mW={'1920px'}
            h={'120px'}
            bg={'#242424'}>
            <Image src={BeloTradeLogoFooter} w={'200px'} />
            <Text color={'white'} fontSize={'15px'}>
                Copyright 2023 Belo Trade Investimentos - Research
            </Text>
        </Box>
    );
}

export default Footer;