import { createContext, useEffect, useState } from "react"
import { unmaskValue } from '../../utils/unmaskValue'
export const FilterContext = createContext()

function FilterProvider({ children }) {
    const [filters, setFilters] = useState(null)
    const currentUrl = window.location.href;

    const [filterData, setFilterData] = useState(
        currentUrl.endsWith('strategies') ?
            JSON.parse(localStorage.getItem('STPage')) : undefined
    )
    // const [filterData, setFilterData] = useState(JSON.parse(
    //     localStorage.getItem('STPage') ?
    //         localStorage.getItem('STPage') : null))

    const [dataBeforeFilter, setDataBeforeFilter] = useState(
        currentUrl.endsWith('strategies') ?
            JSON.parse(localStorage.getItem('STPage')) : undefined
    )

    // useEffect(() => {
    //     console.log('useEfferct')
    //     setFilterData(
    //         currentUrl.endsWith('strategies') ?
    //             JSON.parse(localStorage.getItem('STPage')) : undefined
    //     )
    //     setDataBeforeFilter(
    //         currentUrl.endsWith('strategies') ?
    //             JSON.parse(localStorage.getItem('STPage')) : undefined)
    // }, [])

    const saveFilter = (filters) => {
        setFilters(filters)
    };

    const updateFilter = (column, value) => {
        setFilters({ ...filters, [column]: value })
        applyFilter(dataBeforeFilter, { ...filters, [column]: value })
    };

    const clearFilter = (column) => {
        if (column) {
            let filtersDeleted = { ...filters }
            delete filtersDeleted[column]
            setFilters(filtersDeleted)
            applyFilter(dataBeforeFilter, filtersDeleted)
        } else
            setFilters()
    };

    const defineBeforeData = (data) => {
        setDataBeforeFilter(data)
    }

    const applyFilter = (dataToApply, filters, setData = true) => {
        let dataToApplyClone = Object.assign({}, dataToApply);


        let newDatWithFilter = dataToApplyClone['symbols'].filter(symbol => {
            let filtersToApply = Object.entries(filters)
            let data = filtersToApply.filter((data) => {
                if (data[0] === 'Símbolo') {
                    return symbol === data[1].value
                }
                else if (data[0] !== 'OI' && data[1]) {
                    if (data[1].filter === '>')
                        return parseFloat(dataToApplyClone[data[0]][symbol]) > parseFloat(data[1].value)
                    if (data[1].filter === '>=')
                        return parseFloat(dataToApplyClone[data[0]][symbol]) >= parseFloat(data[1].value)
                    if (data[1].filter === '<')
                        return parseFloat(dataToApplyClone[data[0]][symbol]) < parseFloat(data[1].value)
                    if (data[1].filter === '<=')
                        return parseFloat(dataToApplyClone[data[0]][symbol]) <= parseFloat(data[1].value)
                    if (data[1].filter === '=')
                        return parseFloat(dataToApplyClone[data[0]][symbol]) === parseFloat(data[1].value)
                } else {
                    if (data[1].filter === '>')
                        return dataToApplyClone[data[0]][symbol] > unmaskValue(data[1].value)
                    if (data[1].filter === '>=')
                        return dataToApplyClone[data[0]][symbol] >= unmaskValue(data[1].value)
                    if (data[1].filter === '<')
                        return dataToApplyClone[data[0]][symbol] < unmaskValue(data[1].value)
                    if (data[1].filter === '<=')
                        return dataToApplyClone[data[0]][symbol] <= unmaskValue(data[1].value)
                    if (data[1].filter === '=')
                        return dataToApplyClone[data[0]][symbol] === unmaskValue(data[1].value)
                }

            })
            if (filtersToApply.length === data.length)
                return true
            else
                return false
        })
        dataToApplyClone.symbols = newDatWithFilter.sort()
        if (setData) {
            setFilterData(dataToApplyClone)
            setFilters(filters)
            setDataBeforeFilter(dataToApply)
        }
        return dataToApplyClone
    }

    return (
        <FilterContext.Provider value={{
            saveFilter, clearFilter, applyFilter, filters,
            filterData, dataBeforeFilter, setFilters, updateFilter,
            defineBeforeData, setFilterData
        }}>
            {children}
        </FilterContext.Provider>
    )
}
export default FilterProvider