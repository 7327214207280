// Define the url api
export const api = process.env.REACT_APP_URL_BACKEND;

// Function to make request in rest server
//  token = null, image = null
export const requestConfig = (method, data, token) => {
    let config;

    if (method === "DELETE" || data === null) {
        config = {
            method,
            headers: {},
        };
    
    } 
    else if (data instanceof FormData) {
        config = {
          method,
          body: data,
          headers: {},
        };
    }     
    else {
        config = {
            method,
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        };
    }

    // if (token) {
    //     //config.headers.Authorization = `Bearer ${token}`;
    //     config.headers.Authorization = `${token}`;
    // }
    if (token) {
        config.headers['authorization'] = "bearer " + token
    }
    return config;
};
