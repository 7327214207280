import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import backgroundImageLogin from '../../assets/dashboard-login.png'
import verificationIcon from '../../assets/verificationIcon.png'
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import authService from "../../services/authService";
import { toast } from "react-toastify";

const Verification = () => {
    const { code } = useParams()
    const [loading, setLoading] = useState(true)
    const [msgErro, setMsgErro] = useState(false)

    useEffect(() => {
        async function fetchVerification() {
            try {
                if (!code) {
                    toast.success('Código inválido. ')
                    return
                }
                let response = await authService.checkVerificationCode({ code })
                console.log('response')
                console.log(response)
                if (response.statusCode >= 400) {
                    toast.error(response.error)
                    // setLoading(false)
                } else {
                    // navigate('/login')
                    toast.success('Conta verificada com sucesso. ')
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                toast.success('Algo deu errado. ')
            }
        }
        fetchVerification()
    }, [])

    return (
        <Box maxW={'1920px'} h={'100vh'} bg={'#000000'}>
            <Box h={'90%'} backgroundImage={`url(${backgroundImageLogin})`} backgroundPosition={'100% 35%'} >
                <Flex
                    height={'100%'}
                    flexDirection={'column'}>
                    <Box maxW={'95rem'} h={'148px'} alignItems={'center'} margin={'auto'} flex={'1'}>
                        <Text
                            color={'white'}
                            fontSize={'42px'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                            marginTop={'35px'}
                        >
                            BELO TRADE RESEARCH
                        </Text>
                        <Box maxW={'95rem'} h={'59px'} alignItems={'center'}>
                            <Text
                                color={'white'}
                                fontSize={'16px'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                marginTop={'15px'}
                            >
                                TODAS  AS  INFORMAÇÕES  PARA  UM  <i>CRYPTO TRADER</i>  &nbsp;EM  UM  SO  LUGAR
                            </Text>
                        </Box>
                        <Box maxW={'95rem'} h={'59px'} alignItems={'center'}>
                            <Text
                                color={'white'}
                                fontSize={'16px'}
                                textAlign={'center'}
                                fontWeight={'bold'}
                                marginTop={'15px'}
                            >
                                Verificação
                            </Text>
                        </Box>
                        <Box 
                            maxW={'95rem'} h={'59px'} 
                            display={'flex'} alignItems={'center'} 
                            justifyContent={'center'}>
                            {loading ?
                                (<ReactLoading type={'spin'} color="#fff" width={'25px'} />) :
                                (<>
                                    <Text
                                        color={'white'}
                                        fontSize={'16px'}
                                        textAlign={'center'}
                                        fontWeight={'bold'}
                                    >
                                        Verificação concluida com sucesso
                                    </Text>
                                    &nbsp;
                                    <Image src={verificationIcon} w={'16px'} />
                                </>)}
                            {!msgErro ? '' : (
                                <Text
                                    color={'white'}
                                    fontSize={'16px'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                >
                                    Código inválido.
                                </Text>
                            )}

                        </Box>
                    </Box>
                </Flex>
            </Box>
        </Box >
    );
}

export default Verification;