import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
// import ModalIndicators from "../ModalIndicators/ModalIndicators";

const ModalColumns = ({ handleSave }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [config, setConfig] = useState([])

    const save = (column) => {
        console.log(column.split(':'))
        let newColumn = column.split(':')
        let indicatorExist = config.find(e => e.startsWith(newColumn[0]))
        if (newColumn[1]) {
            if (indicatorExist) {
                let newColumns = config.filter(e => !e.startsWith(newColumn[0]))
                newColumns.push(column)
                setConfig(newColumns)
            } else
                setConfig([
                    ...config, column
                ])
        }
        else {
            let newConfig = config.filter(indicator => !indicator.startsWith(column))
            setConfig(newConfig)
        }
        // Aqui vai chamar todas as configs da modal até o momento e disparar o metodo handle la da componente stategies para aplicar a table
    }

    function saveConfig() {
        handleSave(config, 'get')
        setConfig([])
        onClose()
    }

    console.log('config')
    console.log(config)
    return (
        <>
            <Button
                marginTop={'5px'}
                marginBottom={'5px'}
                onClick={onOpen}
                bg='header'
                color='white'
                _hover={{ bg: 'secondary', color: 'activeGreen' }}>Colunas</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Configure sua estratégia</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex flexDirection={'column'} alignItems={'center'}>
                            <Text fontWeight={'bold'} fontSize={'14px'} w={'60%'}>Long Short Ratio: </Text>
                            <Select width={250} onChange={(e) => save('LSR:' + e.target.value,)}>
                                <option></option>
                                <option value='lsr' >LSR</option>
                                <option value='15m' >LSR:15m</option>
                                <option value='1h' >LSR:1h</option>
                                <option value='4h' >LSR:4h</option>
                                <option value='24h' >LSR:24</option>
                            </Select>
                            <br />
                            <Text fontWeight={'bold'} fontSize={'14px'} w={'60%'}>Open interest: </Text>
                            <Select width={250} onChange={(e) => save('OI:' + e.target.value,)}>
                                <option></option>
                                <option value='OI'> OI $</option>
                                <option value='15m'> OI:15m</option>
                                <option value='1h'> OI:1h</option>
                                <option value='4h'> OI:4h</option>
                                <option value='24h'> OI:24</option>
                            </Select>
                            <br />
                            <Text fontWeight={'bold'} fontSize={'14px'} w={'60%'}>Market Volume: </Text>
                            <Select width={250} onChange={(e) => save('MV:' + e.target.value,)}>
                                <option></option>
                                <option value='15m'>MV:15m</option>
                                <option value='1h'>MV:1h</option>
                                <option value='4h'>MV:4h</option>
                            </Select>
                            <br />
                            <Text fontWeight={'bold'} fontSize={'14px'} w={'60%'}>Funding Rate: </Text>
                            <Select width={250} onChange={(e) => save('FR:' + e.target.value,)}>
                                <option></option>
                                <option value='FR'>FR</option>
                            </Select>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost' onClick={() => saveConfig()}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}

export default ModalColumns;