import { createContext, useState } from "react"
import { GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../services/firebase";
import authService from "../../services/authService";
import { signInWithPopup } from 'firebase/auth'
import { toast } from "react-toastify";


export const AuthContext = createContext()


function AuthProvider({ children }) {
    const [authToken, setAuthToken] = useState(null)

    const AuthLogin = async (sucess, error) => {
        try {
            const provider = new GoogleAuthProvider()
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    try {
                        let token = await result.user.getIdToken()
                        sucess({
                            email: result.user.email,
                            username: result.user.displayName,
                            photo: result.user.photoURL,
                            token: token
                        })
                    } catch (err) {
                        error("Algo deu errado contate o suporte da plataforma. ")
                    }

                })
                .catch(err => {
                    error("Email inválido. ")
                })
        } catch (error) {
            console.log(error);
        }
    };

    const Logout = async () => {
        try {
            await authService.logout()
            setAuthToken()
            localStorage.removeItem('token')
            localStorage.removeItem('research-user-data')
            localStorage.removeItem('discId')
            localStorage.setItem('permissions',JSON.stringify([]))
            toast.success('Sessão expirada com sucesso. ')
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <AuthContext.Provider value={{ authToken, AuthLogin, Logout, setAuthToken }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider