import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import Timer from "../../components/Timer/Timer";
import styles from './MarketVolume.module.css'
import TablePerson from "./Table/Table";
import { useState } from "react";
import axios from 'axios'
import { useEffect } from "react";
import NotificationSongGlobal from "../../components/NotificationSongGlobal/NotificationSongGlocal";
import TableData from "../../services/TableDataService";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";



const UNAVAILABLE_SYMBOLS = ['btc_221230', 'btc_230331', 'eth_221230', 'eth_230331', 'ray', 'tlm', 'bts', 'sc', 'ftt', 'srm', 'cvc', 'fet', 'fxs']
const MarketVolume = ({ updateNow }) => {

    const columnsFix = {
        'Símbolos': false,
        '15m Δ%': false,
        '1h Δ%': false,
        '4h Δ%': false
    }

    const [data, setData] = useState(JSON.parse(localStorage.getItem('MkPage')))
    const [updatedPage, setUpdatedPage] = useState(false)
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    const [columns, setColumns] = useState(columnsFix)
    const [order, setOrder] = useState(true)
    const [symbols, setSymbols] = useState([])
    const [filterApplied, setFilterApplied] = useState([])
    const [updateTable, setUpdateTable] = useState(false)
    const [timer, setTimer] = useState(false)

    function updateSymbols(newData, column) {

        let newColumnsConfig = columnsFix
        newColumnsConfig[column] = columns[column] ? false : true
        setOrder(!order)
        setColumns(newColumnsConfig)

        setData(newData)
    }

    function handleFilterTable(newData, column) {
        if (filterApplied.length && filterApplied.indexOf(column) !== -1) {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setData(newData)
        } else {
            setUpdateTable(!updateTable)
            setTimeout(() => {
                setUpdateTable(true)
            }, 100)
            setFilterApplied([...filterApplied, column])
            setData(newData)
        }
    }

    function formatData(data, symbol) {
        console.log('data - formatData')
        console.log(data)
        setLastUpdatedAt(new Date(data['MV'][0].createdAt))
        // symbol = symbol.map(e => e.symbol)
        let symbols = []
        let newData = { '15m': {}, '1h': {}, '4h': {}, symbols: symbols }
        data['MV'].map(d => {
            symbols.push(d.symbol)
            newData['15m'] = { ...newData['15m'], [d.symbol]: parseFloat(d['15m']) }
            newData['1h'] = { ...newData['1h'], [d.symbol]: parseFloat(d['1h']) }
            newData['4h'] = { ...newData['4h'], [d.symbol]: parseFloat(d['4h']) }
        })
        return newData
    }

    // async function getData(symbolsData, period) {
    //     const url = 'https://fapi.binance.com/futures/data/takerlongshortRatio'
    //     let newDatas = {}
    //     symbolsData.map(async (symbol) => {
    //         axios.get(
    //             `${url}?symbol=${symbol.toUpperCase()}USDT&period=${period}`
    //         ).then((resp) => {
    //             newDatas[symbol] = resp.data
    //         }).catch(err => console.log(err))
    //     })

    //     return { [period]: newDatas }
    // }

    // function prepareData(data15mApi, data1hApi, data4hApi, symbolsData) {
    //     let newData = {
    //         '15m': {},
    //         '1h': {},
    //         '4h': {},
    //         '24h': {},
    //     }

    //     symbolsData.map((symbol) => {
    //         let data15m = (parseFloat(data15mApi['15m'][symbol].at(-2)['buySellRatio']) - 1) * 100
    //         let data1h = (parseFloat(data1hApi['1h'][symbol].at(-2)['buySellRatio']) - 1) * 100
    //         let data4h = (parseFloat(data4hApi['4h'][symbol].at(-2)['buySellRatio'] - 1)) * 100

    //         newData['15m'] = { ...newData['15m'], [symbol]: data15m }
    //         newData['1h'] = { ...newData['1h'], [symbol]: data1h }
    //         newData['4h'] = { ...newData['4h'], [symbol]: data4h }
    //         // newData['24h'] = { ...newData['24h'], [symbol]: data1d }
    //     })
    //     setUpdateTable(true)
    //     return newData
    // }

    useEffect(() => {
        async function fetchData() {
            let symbols = await TableData.getSymbols()
            let response = await TableData.getTableData('MV')
            let dataFormated = formatData(response['data'], symbols['data'])
            localStorage.setItem('MkPage', JSON.stringify(dataFormated))
            setData(dataFormated)
            setUpdateTable(true)
            // axios.get('https://fapi.binance.com/fapi/v1/ticker/price')
            //     .then(async (resp) => {
            //         const correctSymbols = resp.data
            //             .filter((item) => item.symbol.includes('USDT'))
            //             .map((item) => item.symbol.replace('USDT', ''))
            //             .filter(
            //                 (item) => UNAVAILABLE_SYMBOLS.indexOf(item.toLocaleLowerCase()) === -1
            //             );
            //         setSymbols(correctSymbols)
            //         localStorage.setItem('symbols', JSON.stringify(correctSymbols))
            //         let data5m = await getData(correctSymbols, '15m')
            //         let data1h = await getData(correctSymbols, '1h')
            //         let data4h = await getData(correctSymbols, '4h')

            //         setTimeout(() => {
            //             let newData = prepareData(data5m, data1h, data4h, correctSymbols)
            //             setData([
            //                 { 'symbols': correctSymbols.sort() },
            //                 { '15m': newData["15m"] }, { '1h': newData["1h"] },
            //                 { '4h': newData["4h"] }
            //                 // , { '24h': newData["24h"] }
            //             ])
            //         }, 4000)

            //         // await getData(correctSymbols, '1h')
            //         // await getData(correctSymbols, '4h')
            //         // await getData(correctSymbols, '24h')
            //     })
            //     .catch(err => console.log(err))


            // setData({ ...data, '15m': await prepareData() })
        }
        fetchData()
        // if (updateNow) {
        //     setUpdateTable(false)
        //     fetchData()
        //     setTimeout(() => {
        //         setUpdateTable(true);
        //     }, 4000)
        // }
    }, [updatedPage])
    // }, [, updateNow])



    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='10px'>
                        Market Volume
                    </Text>
                    <NotificationSongGlobal />
                    <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                    {/* <GenericTimer lastUpdatedAt={lastUpdatedAt} /> */}
                    {/* <div>
                        <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                            <Timer
                                loadData={data}
                                handleUpdateTable={setTimer}
                                statusTimer={timer}
                                actionUpdateTable={setUpdateTable} />
                        </Flex>
                    </div> */}
                    <Flex
                        maxHeight={'78vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}>
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} symbols={symbols}
                                dataTable={data} handleUpdateSymbols={updateSymbols}
                                updateTable={updateTable} order={order}
                                handleFilterTable={handleFilterTable}
                                filtersApplieds={filterApplied} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default MarketVolume;