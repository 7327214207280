import { Flex, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
// import EmBreve from "../../components/EmBreve/Embreve";
import TablePerson from "./Table/Table";
import styles from './StrategieAlerts.module.css';
import historicStrategiesService from '../../services/historicStrategiesService';
import TableData from "../../services/TableDataService";
import { GenericTimer } from "../../components/GenericTimer/GenericTimer";

const StrategieAlerts = () => {

    const columns = ['Símbolo', 'Estratégia', 'LSR:4h','OI:4h','FR', 'Data']
    const [data, setData] = useState([])
    const [lastUpdatedAt, setLastUpdatedAt] = useState()
    const [updatedPage, setUpdatedPage] = useState(false)
    const [countStrategies, setCountStrategies] = useState({
        long: 0,
        short: 0,
        Flong: 0,
        Fshort: 0
    })

    useEffect(() => {
        async function fetchData() {
            let response = await historicStrategiesService.getHistoricStrategies()
            let countFundingLong = response['data'].filter(s => s.strategieName === 'FUNDING LONG')
            let countFundingShort = response['data'].filter(s => s.strategieName === 'FUNDING SHORT')
            let countBPL = response['data'].filter(s => s.strategieName === 'BREAKOUT PRO LONG')
            let countBPS = response['data'].filter(s => s.strategieName === 'BREAKOUT PRO SHORT')
            let counts = {
                long: countBPL.length,
                short: countBPS.length,
                Flong: countFundingLong.length,
                Fshort: countFundingShort.length
            }
            setCountStrategies(counts)
            setData(response['data'])
            console.log('data')
            console.log(data)
            // await getStrategies()
        }
        fetchData()
    }, [updatedPage])

    return (
        <div className={styles.container}>
            <div>
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Text
                        fontSize='32px'
                        color='white'
                        fontWeight='bolder'
                        margin='5px'>
                        Alertas dos Estratégias
                    </Text>
                    {/* <Text
                        fontSize={'14px'}
                        textAlign={'center'}
                        color={'text'}
                        fontWeight={'700'}
                        fontFamily={'roboto'}>
                        Numero de Moedas por estratégias:
                    </Text> */}
                    <Flex>
                        <Text
                            fontWeight={'bold'}
                            fontSize='14px'
                            color='white'
                            margin='10px'>
                            BREAKOUT PRO LONG: {countStrategies['long']}
                            <br />
                            BREAKOUT PRO SHORT: {countStrategies['short']}
                            <br />
                        </Text>
                        <Text
                            fontWeight={'bold'}
                            fontSize='14px'
                            color='white'
                            margin='10px'>
                            FUNDING LONG: {countStrategies['Flong']}
                            <br />
                            FUNDING SHORT: {countStrategies['Fshort']}
                        </Text>
                    </Flex>
                    {/* <NotificationSong trigger={alert} platform={'StrategieAlerts'} /> */}
                    <div>
                        <Flex flexDirection={'column'} justifyContent={'flex-start'} marginTop={'15px'}>
                            <GenericTimer lastUpdatedAt={lastUpdatedAt} updateTable={setUpdatedPage} />
                        </Flex>
                    </div>
                    <Flex
                        maxHeight={'73vh'}
                        maxWidth={'80%'}
                        borderRadius={'30px'}
                        padding={'0px 0px'}
                        flexDirection={'column'}
                        boxShadow={'0px 0px 10px 0px rgba(20, 20, 20, 0.3)'}
                        transition={'1s'}
                        >
                        <div className={styles.tableContainer}>
                            <TablePerson columns={columns} dataTable={data} />
                        </div>
                    </Flex>

                </Flex>
            </div>
        </div>

    );
}

export default StrategieAlerts;