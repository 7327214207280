import { border, Button, Flex, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Square, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRef, useState } from 'react';

import { AiFillBell, AiOutlineBell, AiFillSetting } from 'react-icons/ai'
import NotificationSoundOne from "../../assets/notifications/alert0.mp3";
import NotificationSoundTwo from "../../assets/notifications/alert1.wav";
import NotificationSoundTree from "../../assets/notifications/alert2.wav";
import NotificationSoundFour from "../../assets/notifications/alert3.wav";
import NotificationSoundFive from "../../assets/notifications/alert4.wav";
import NotificationSoundSix from "../../assets/notifications/alert5.wav";

import alertsStatus from '../../services/alertStatus'

const NotificationSong = ({ trigger, platform, isFirstTimer = false, changeTime }) => {
    const songs = {
        'a': { song: NotificationSoundOne, active: false, color: '#3d7b30' },
        'b': { song: NotificationSoundTwo, active: false, color: '#2e6e24' },
        'c': { song: NotificationSoundTree, active: false, color: '#1f6018' },
        'd': { song: NotificationSoundFour, active: false, color: '#0f530c' },
        'e': { song: NotificationSoundFive, active: false, color: '#0e3f0b' },
        'f': { song: NotificationSoundSix, active: false, color: '#004500' },
    }
    let memoryInSongs = localStorage.getItem('alertsSongs')

    const [click, setClick] = useState()
    const [clickSquared, setClickSquared] = useState(false)
    const [selectSong, setSelectSong] = useState(memoryInSongs ? JSON.parse(memoryInSongs) : songs)
    const [currentSong, setCurrentSong] = useState()

    const audioPlayer = useRef(null);

    useEffect(() => {
        alertsStatus.getAlertStatus(platform).then(response => {
            if (response.data) {
                if (response.data.songAlert) {
                    setCurrentSong(response.data.songAlert)
                    playAudio()
                } else {
                    setCurrentSong(NotificationSoundOne)
                }
                setClick(response.data.alarmTriggerStatus)
            }
        }).catch(err => console.log(err))

        // if (click) {
        //     if (!isFirstTimer) {
        //         playAudio()
        //     } else {
        //         // eslint-disable-next-line react-hooks/exhaustive-deps
        //         changeTime(false)
        //     }
        // }
    }, [trigger])

    function playAudio() {
        audioPlayer.current.play()
            .then(resp => {
                alertsStatus.updateAlertStatus({
                    songAlert: currentSong
                }, platform)
                localStorage.setItem('alertsSongs', JSON.stringify(selectSong))
            })
            .catch(err => {
                console.log(err)
                setClickSquared(true)
                setTimeout(() => setClickSquared(false), 2000)
            });
    }

    function handleClick(clicked) {
        let status = clicked ? clicked : false

        alertsStatus.updateAlertStatus({
            alarmTriggerStatus: status
        }, platform)

        setClick(clicked)
        playAudio()
    }

    function clearOtherFilters() {
        let currentSongs = selectSong

        Object.keys(selectSong).map(e => {
            currentSongs[e]['active'] = false
        })
        setSelectSong(currentSongs)
    }

    function updateSongs(chave, value) {
        setSelectSong(prev => {
            prev[chave]['active'] = value
            return prev
        })
    }

    function handleClickSquare(newSong, chave) {
        audioPlayer.current.load()
        setCurrentSong(newSong)
        playAudio()
        clearOtherFilters()
        updateSongs(chave, true)
    }
    return (
        <>
            <audio ref={audioPlayer} src={currentSong} />
            <Flex marginTop={'15px'}>
                {
                    click
                        ? (<AiFillBell cursor={'pointer'} color={'white'} size={'25px'} onClick={() => {
                            setClick(false)
                            alertsStatus.updateAlertStatus({
                                alarmTriggerStatus: false
                            }, platform)
                            return
                        }} />)
                        : (<AiOutlineBell cursor={'pointer'} color={'white'} size={'25px'} onClick={() => handleClick(true)} />)
                }

                <Popover>
                    <PopoverTrigger>
                        <div><AiFillSetting cursor={'pointer'} color={'white'} size={'25px'} /></div>
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Selecione o som para os alarmes!</PopoverHeader>
                        <PopoverBody >
                            <Flex>
                                {Object.entries(selectSong).map((e, i) => (
                                    <Square
                                        key={i}
                                        size='25px'
                                        bg={e[1].color}
                                        color='black'
                                        margin='3px'
                                        cursor={'pointer'}
                                        border={e[1].active ? '2px solid black' : ''}
                                        onClick={() => handleClickSquare(e[1].song, e[0])}>
                                        {/* {e[1].active ? JSON.stringify(e[1]) : ''} */}
                                    </Square>))}
                            </Flex>
                            {clickSquared ? (<Text color={'green'} fontSize={'10px'}>Click novamente para salvar...</Text>) : ('')}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>

            </Flex>
        </>);
}

export default NotificationSong;